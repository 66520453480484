import { Component } from "react";
import Parse from "parse/node";
import {
  BACK4APP_MASTER_KEY,
  BACK4APP_URL,
  BACK4APP_APPLICATIONID,
  BACK4APP_JS_KEY
} from "../../util/back4app/Constants";
import { CONDITIONS, REAZONS } from "../../Components/Test/Constants";

export default class apiB4A extends Component {
  constructor() {
    super();
    Parse.serverURL = BACK4APP_URL;
    Parse.initialize(
      BACK4APP_APPLICATIONID,
      BACK4APP_JS_KEY,
      BACK4APP_MASTER_KEY
    );
  }

  /* Get branch by centro name */
  getBranchByCentro = async centro => {
    const Branch = Parse.Object.extend("Branch");
    const query = new Parse.Query(Branch);
    query.equalTo("centro", centro);
    const response = await query.find().then(
      results => JSON.stringify(results),
      error => {
        console.error("Error while fetching ParseObjects", error);
      }
    );
    return JSON.parse(response);
  };

  /* Get branches for Acuity */
  getBranches = async country => {
    const Branch = Parse.Object.extend("Branch");
    const query = new Parse.Query(Branch);
    query.equalTo("country", country);
    const response = await query.find().then(
      results => JSON.stringify(results),
      error => {
        console.error("Error while fetching ParseObjects", error);
      }
    );
    return JSON.parse(response);
  };

  saveAbandon = async data => {
    const AbandonedCart = Parse.Object.extend("AbandonedCart");
    const newObject = new AbandonedCart();
    newObject.set("lastName", data.lastname);
    newObject.set("name", data.name);
    newObject.set("phone", data.mobile);
    newObject.set("email", data.email);
    newObject.set("branch", data.sucursal);
    newObject.set("coupon", data.coupon);
    newObject.set("paid", false);
    newObject.set("price", data.finalPrice);

    return newObject.save().then(
      async result => {
        return result;
      },
      error => {
        console.error("Error while creating Abandon: ", error);
      }
    );
  };

  updateAbandoned = idObject => {
    const AbandonedCart = Parse.Object.extend("AbandonedCart");
    const query = new Parse.Query(AbandonedCart);
    query.get(idObject).then(object => {
      object.set("paid", true);
      object.save().then(
        response => {
        },
        error => {
        }
      );
    });
  };

  /* Save data from pop up modal */
  saveFormPopUp = async (
    phone,
    email,
    name,
    countryOps,
    campaign,
    source,
    medium,
    creative,
    visit
  ) => {
    const Contact = Parse.Object.extend("HomeModal")
    const myNewObject = new Contact();

    myNewObject.set('phone', phone);
    myNewObject.set('email', email);
    myNewObject.set('name', name);
    myNewObject.set('countryOps', countryOps);
    myNewObject.set('campaign', campaign);
    myNewObject.set('source', source);
    myNewObject.set('visit', visit);
    myNewObject.set('medium', medium);
    myNewObject.set('creative', creative);

    myNewObject.save().then(
      (result) => {
      },
      (error) => {
      }
    );
  }

  /* Save data from HomeFrom */
  saveHomeForm = async (
    phone,
    email,
    name,
    countryOps,
    reason,
    campaign,
    source,
    visit,
    medium,
    creative
  ) => {
    const Contact = Parse.Object.extend("HomeForm");
    const contact = new Contact();

    new Promise((resolve, reject) => {
      contact
        .save({
          phone: phone,
          email: email,
          name: name,
          countryOps: countryOps,
          reason: reason,
          campaign: campaign,
          source: source,
          visit: visit,
          medium: medium,
          creative: creative
        })
        .then(res => {
          resolve(true);
        })
        .catch(err => {
          console.log(err);
        });
    });
  };

  getInterests = async country => {
    const Installment = Parse.Object.extend("Installment");
    const query = new Parse.Query(Installment);
    query.ascending("quantity");
    query.equalTo("country", country);
    query.equalTo("active", true);
    return query.find().then(
      results => {
        const data = JSON.stringify(results);
        return JSON.parse(data);
      },
      error => {
        console.error("Error while fetching Installment", error);
      }
    );
  };

  saveCandidate = answers => {
    const Candidate = Parse.Object.extend("Candidate");
    const myNewObject = new Candidate();
    let isCandidate = false;

    const answer2 = [];
    if (answers[1].encimados) answer2.push("encimados");
    if (answers[1].enderezar) answer2.push("enderezar");
    if (answers[1].mordida) answer2.push("mordida");
    if (answers[1].separados) answer2.push("separados");

    let cont = 0;
    const answer5 = [];
    answers[4].forEach(ans => {
      if (ans) answer5.push(CONDITIONS[cont].sku);
      cont++;
    });
    cont = 0;
    let answer6;
    if (answers[5] === undefined) {
      answer6 = null;
    } else {
      answers[5].forEach(ans => {
        if (ans) answer6 = REAZONS[cont].sku;
        cont++;
      });
      isCandidate = true;
    }

    myNewObject.set("isAdult", answers[2]);
    myNewObject.set("smileType", answer2);
    myNewObject.set("brackets", answers[3]);
    myNewObject.set("conditions", answer5);
    myNewObject.set("reazons", answer6);
    myNewObject.set("isCandidate", isCandidate);
    myNewObject.set("name", answers[0]);
    myNewObject.set("email", answers[6].email);
    myNewObject.set("phone", answers[6].phone);
    myNewObject.set("country", answers[7]);

    myNewObject.save().then(
      result => {
        if (typeof document !== "undefined") return result;
      },
      error => {
        if (typeof document !== "undefined")
          console.error("Error while creating Candidate: ", error);
      }
    );
  };
}
