import React from "react";

export const Step = props => {
  return (
    <div className="d-flex justify-content-around steps-distance flex-wrap row">
      <b className="indications-name align-self-center title">{props.title}</b>
      <div className="img text-center d-flex justify-content-center align-self-center">
        <img
          src={props.img}
          alt={"logo"}
          width={props.imgWidth}
          height={props.imgHeight}
        />
      </div>
      <p className="indications align-self-center description">{props.desc}</p>
    </div>
  );
};
