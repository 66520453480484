import React from "react";
import styles from "./TitleSection.module.css";

const titleSection = () => {
  return (
    <>
      <div className={`${styles.titleCentered} ${styles.mediumTitle}`}>
        <div className={styles.smileIcon}></div>
        <span>
          Dientes alineados en 3 pasos
        </span>
      </div>
    </>
  );
};

export default titleSection;
