import React from "react";
import styles from "./Medios.module.css";
import excelsior from "../../assets/images/moons_excelsior.svg";
import elle from "../../assets/images/moons_elle.svg";
import forbes from "../../assets/images/moons_forbes.svg";
import expansion from "../../assets/images/moons_expansion.svg";

import laRepublica from "../../assets/images/La_Republica.svg";
import laNota from "../../assets/images/La_Nota.svg";
import Dinero from "../../assets/images/Dinero.svg";
import Portafolio from "../../assets/images/Portafolio.svg";

import rating from "../../assets/images/moons_calificaciones_opiniones.svg";
const medios = ({ countryData }) => {
  const medios =
    countryData.domain === "mx"
      ? [excelsior, elle, forbes, expansion]
      : [laRepublica, laNota, Dinero, Portafolio];
  return (
    <>
      <div>
        <div className={`${styles.rating} ${styles.hideDesktop}`}>
          <img alt="moons calificaciones opiniones" src={rating} />
        </div>
      </div>
      <div className={styles.medios}>
        <div className={styles.mediosRow}>
          <div className={styles.medio}>
            <img alt="Moons Excelsior" src={medios[0]} />
          </div>
          <div
            className={`${styles.medio} ${
              countryData.domain === "mx" ? styles.elle : ""
            }`}
          >
            <img alt="Moons Elle" src={medios[1]} />
          </div>
          <div className={`${styles.rating} ${styles.hideMobile}`}>
            <img alt="Moons Calificacion" src={rating} />
          </div>
          <div className={styles.medio}>
            <img alt="Moons Forbes" src={medios[2]} />
          </div>
          <div className={styles.medio}>
            <img alt="Moons Expansion" src={medios[3]} />
          </div>
        </div>
      </div>
    </>
  );
};

export default medios;
