export const CONDITIONS = [
  { code: 0, desc: "Tengo implantes dentales", sku: "implantes" },
  { code: 1, desc: "Me falta uno o más dientes", sku: "dientes_faltantes" },
  { code: 2, desc: "Tengo caries sin restaurar", sku: "caries" },
  { code: 3, desc: "Encías inflamadas o sangrantes", sku: "problemas_encias" },
  { code: 4, desc: "Tengo un retenedor permanente", sku: "retenedor" },
  { code: 5, desc: "Estoy tomando bifosfonatos", sku: "bifosfonatos" },
  { code: 6, desc: "Ninguna de las anteriores", sku: "ninguna" },
];

export const REAZONS = [
  { code: 0, desc: "Me voy a casar", sku: "marry" },
  { code: 1, desc: "Quiero mejorar mi salud", sku: "healt" },
  { code: 2, desc: "Quiero sentirme y verme mejor", sku: "look_like" },
  { code: 3, desc: "Otra", sku: "other" },
];

export const VIDEOLLAMADA_URL = "https://mycall.mymoons.mx/";
