import React from "react";
import star from "../../assets/images/star.svg";

export const Comment = props => {
  const styles = props.styles;
  return (
    <div className={styles.rielBox}>
      <div className={styles.rielItem}>
        <div className={styles.quotes}>“</div>
        <div className={styles.reviewText}>
          <div>
            <img className={styles.reviewStar} src={star} alt="star" />
            <img className={styles.reviewStar} src={star} alt="star" />
            <img className={styles.reviewStar} src={star} alt="star" />
            <img className={styles.reviewStar} src={star} alt="star" />
            <img className={styles.reviewStar} src={star} alt="star" />
          </div>
          <div>{props.text}</div>
        </div>
        <div className={styles.quotes}>”</div>
      </div>
      <div className={styles.userReview}>
        <div className={styles.avatar}>
          <img alt="avatar" src={props.avatar} />
        </div>
        <div className={styles.name}>{props.name}</div>
      </div>
    </div>
  );
};
