import React, { Component } from "react";
import styles from "./Reviews.module.css";
import NextButton from "../NextButton/NextButton";
import avatarReg from "../../assets/images/avatar.png";
import avatar2Reg from "../../assets/images/avatar2.png";
import avatar3Reg from "../../assets/images/avatar3.png";
import avatarWebp from "../../assets/images/webp/avatar.webp";
import avatar2Webp from "../../assets/images/webp/avatar2.webp";
import avatar3Webp from "../../assets/images/webp/avatar3.webp";
import { Comment } from "./Comment";

class Carousel extends Component {
  timeout = null;
  state = {
    active: 0,
    paused: false,
    rielWidth: window.innerWidth
  };

  moveRiel = index => {
    return () => {
      this.setState({ active: index, paused: true });
    };
  };

  componentDidMount() {
    this.timeout = setInterval(() => {
      if (!this.state.paused) {
        let active = this.state.active + 1 > 2 ? 0 : this.state.active + 1;
        this.setState({ active: active });
      } else {
        this.setState({ paused: false });
      }
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  render() {
    let navs = [0, 1, 2].map(el => {
      let className = [styles.outerCircle];
      if (this.state.active === el) {
        className.push(styles.active);
      }

      return (
        <nav
          key={`nav_${el}`}
          className={className.join(" ")}
          onClick={this.moveRiel(el)}
        >
          <nav className={styles.innerCircle}></nav>
        </nav>
      );
    });

    let avatar = this.props.webp ? avatarWebp : avatarReg;
    let avatar2 = this.props.webp ? avatar2Webp : avatar2Reg;
    let avatar3 = this.props.webp ? avatar3Webp : avatar3Reg;

    const rielWidth = window.innerWidth * 0.8;

    return (
      <div className={styles.boxSection}>
        <div className={styles.reviewBox}>
          <div
            className={`${styles.pricingTitle} ${styles.mediumTitle} ${styles.marginBottom30}`}
          >
            {this.props.title}
          </div>
          <div className={styles.riel}>
            <div
              className={styles.rielContainer}
              style={{ left: this.state.active * rielWidth * -1 }}
            >
              <Comment
                name={"Daniela, 29"}
                text={
                  "La gente en verdad no se da cuenta de que estoy usando los moons! Por fin pude arreglar mis dientes sin aparatos horribles :D"
                }
                avatar={avatar}
                styles={styles}
              />
              <Comment
                name={"Gaby, 34"}
                text={
                  "Al principio no estaba convencida de que funcionaran, sonaba muy bueno para ser verdad. Apenas voy a la mitad del tratamiento y mis dientes ya están mucho más derechos. Me encantan!"
                }
                avatar={avatar2}
                styles={styles}
              />
              <Comment
                name={"Armando, 27"}
                text={
                  "Tuve brackets antes y ni loco los volvería a usar. Los moons son mucho más cómodos y no me da pena saber que los estoy usando! Ojalá los hubiera conocido hace 10 años!!!"
                }
                avatar={avatar3}
                styles={styles}
              />
            </div>
            <div className={styles.rielNavs}>{navs}</div>
          </div>
          <NextButton
            linkTo="/opiniones"
            label="Más reseñas"
            classButton={styles.redEvent}
          />
        </div>
      </div>
    );
  }
}

export default Carousel;
