import React, { Component } from 'react';
import './AppointmentDone.css';
import HelpInfo from '../HelpInfo/HelpInfo';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

class AppointmentDone extends Component {

    state = {
        acuityId: false,
        setPixel: false
    }

    componentDidMount() {

        let acuityId = this.props.match.params.id ? this.props.match.params.id : false;
        if (acuityId) {
            this.setState({ acuityId: acuityId });
            axios.post(`https://khfnloebb4.execute-api.eu-west-1.amazonaws.com/website-notifications/fetch-acuity-appointment`, {
                appointmentId: acuityId
            })
                .then(res => {

                    if (res && res.data && res.data.campaign) {
                        if (res.data.campaign === 'cleverclick') {
                            this.setState({ setPixel: 'cleverclick' });
                        }

                        if (res.data.campaign === 'cleverclick2') {
                            this.setState({ setPixel: 'cleverclick2' });
                        }
                    }
                }).catch(err => { });
        }

        let email = this.props.match.params.email ? this.props.match.params.email : false;
        if (email) {
            this.setState({ email: email });
        }
    }

    render() {

        let cleverPixel = null;
        if (this.state.setPixel === 'cleverclick') {
            cleverPixel = <iframe title="cleverclick" src={`https://www.tcsktrk.com/?nid=120&oid=315&affid=2&adv1=${this.state.acuityId}`} scrolling="no" frameborder="0" width="1" height="1"></iframe>
        }

        if (this.state.setPixel === 'cleverclick2') {
            cleverPixel = <iframe title="cleverclick2" src={`https://www.tcsktrk.com/?nid=120&advid=36&adv1=${this.state.acuityId}`} scrolling="no" frameborder="0" width="1" height="1"></iframe>
        }

        return (
            <div className="center-container ">
                <div className="medium-title margin-top-title">
                    ¡Listo!
                </div>
                <div className="section-space-small regular-text section-space-xs">
                    <p>
                        Tu cita está programada.<br />
                    </p>
                </div>
                {/*<div className="section-space-small" >
                    <div className="container-coupon">
                        <div className="coupon-image">
                            <img alt="coupon" src={couponImage}/>
                        </div>
                        <div className="coupon-content">
                            <div className="accent-coupon">
                                ¿Quieres un descuento de  $2,000 en tu tratamiento?
                            </div>
                            <p className="margin-p-bottom">Paga $2,000 ahora y asegura tu precio de promoción*</p>
                            <StartNow>
                                PAGAR AHORA
                            </StartNow>
                            <p className="margin-p-bottom">*Válido hasta el 31 de mayo de 2019. Aplican restricciones.</p>
                        </div>
                    </div>
                </div>*/}
                <div className="section-space-small subtitle-section center-container align-center">
                    ¿Qué pasará en tu visita?
                </div>

                <div className="section-space-small">
                    <div className="side-columns">
                        <div className="left-content-side">
                            <div>
                                <div className="column-title-small">1. Antes de tu cita</div>
                            </div>
                        </div>
                        <div className="center-content-side-large">
                            <span className="mouth-large-icon"></span>
                            <span className="v-align v-small"></span>
                        </div>
                        <div className="right-content-side regular-text">
                            <div className="colum-title-mobile">
                                <div className="column-title-small">1. Antes de tu cita</div>
                            </div>
                            No es una visita al dentista, pero es importante que tu boca esté bien limpia y fresca.
                        </div>
                    </div>
                    <div className="side-columns">
                        <div className="left-content-side">
                            <div>
                                <div className="column-title-small">2. Llegando</div>
                            </div>
                        </div>
                        <div className="center-content-side-large">
                            <span className="location-icon"></span>
                            <span className="v-align v-small"></span>
                        </div>
                        <div className="right-content-side regular-text">
                            <div className="colum-title-mobile">
                                <div className="column-title-small">2. Llegando</div>
                            </div>
                            Llegarás a uno de nuestros centros radiológicos afiliados. Ellos se encargarán de tu diagnóstico y escaneo 3D.
                        </div>
                    </div>
                    <div className="side-columns">
                        <div className="left-content-side">
                            <div>
                                <div className="column-title-small">3. Personalización de tu plan</div>
                            </div>
                        </div>
                        <div className="center-content-side-large">
                            <span className="talk-icon"></span>
                            <span className="v-align v-small"></span>
                        </div>
                        <div className="right-content-side regular-text">
                            <div className="colum-title-mobile">
                                <div className="column-title-small">3. Personalización de tu plan</div>
                            </div>
                            Haremos preguntas para entender tus metas. Aprovecha para preguntarnos lo quieras ;)
                        </div>
                    </div>
                    <div className="side-columns">
                        <div className="left-content-side">
                            <div>
                                <div className="column-title-small">4. Empieza la acción</div>
                            </div>
                        </div>
                        <div className="center-content-side-large">
                            <span className="cellphone-icon"></span>
                            <span className="v-align v-small"></span>
                        </div>
                        <div className="right-content-side regular-text">
                            <div className="colum-title-mobile">
                                <div className="column-title-small">4. Empieza la acción</div>
                            </div>
                            Haremos el escaneo de tu sonrisa para crear un modelo en 3D. Es idéntico a tus dientes y sirve para diseñar tu tratamiento.
                        </div>
                    </div>
                    <div className="side-columns">
                        <div className="left-content-side">
                            <div>
                                <div className="column-title-small">5. Mira los moons de cerca</div>
                            </div>
                        </div>
                        <div className="center-content-side-large">
                            <span className="alineadores-icon"></span>
                        </div>
                        <div className="right-content-side regular-text">
                            <div className="colum-title-mobile">
                                <div className="column-title-small">5. Mira los moons de cerca</div>
                            </div>
                            Tenemos un kit de muestra para ti. ¡Mira la textura, material y transparencia de los moons!
                        </div>
                    </div>
                </div>
                <div className="section-space align-center-items" >
                    <HelpInfo />
                </div>
                {cleverPixel}
                <img height="1" width="1" style={{ display: "none" }} alt="cita agendada moons" src="https://www.facebook.com/tr?id=427318704548057&ev=Schedule&noscript=1" />
            </div>
        );
    };
}

export default withRouter(AppointmentDone);