import React, { Component } from "react";
import { FaAngleDown } from "react-icons/fa";
import { FaAngleUp } from "react-icons/fa";
import HelpInfo from "../HelpInfo/HelpInfo";
import styles from "./Faq.module.css";
import next from "../../assets/images/next.svg";

class Faq extends Component {
  state = {
    open: null
  };

  toggleQuestion = index => {
    return () => {
      index = this.state.open === index ? null : index;
      this.setState({ open: index });
    };
  };

  render() {
    let questions = this.props.questions
      ? this.props.questions
      : [
          {
            question: "Si hay un precio estándar, ¿mi tratamiento es estándar?",
            answer:
              "¡Nunca! Los moons se diseñan a la medida de tus dientes y de acuerdo a lo que queremos corregir. Con el escaneo 3D al inicio de tu tratamiento, hacemos un modelo preciso de tus dientes que sirve como molde para fabricar tus moons. Cada sonrisa es diferente, ¡jamás podrías usar los moons de alguien más!"
          },
          {
            question: "¿Qué formas de pago aceptan?",
            answer:
              "¡Nunca! Los moons se diseñan a la medida de tus dientes y de acuerdo a lo que queremos corregir. Con el escaneo 3D al inicio de tu tratamiento, hacemos un modelo preciso de tus dientes que sirve como molde para fabricar tus moons. Cada sonrisa es diferente, ¡jamás podrías usar los moons de alguien más!"
          },
          {
            question: "¿Puedo usar mi seguro dental para los moons?",
            answer:
              "¡Nunca! Los moons se diseñan a la medida de tus dientes y de acuerdo a lo que queremos corregir. Con el escaneo 3D al inicio de tu tratamiento, hacemos un modelo preciso de tus dientes que sirve como molde para fabricar tus moons. Cada sonrisa es diferente, ¡jamás podrías usar los moons de alguien más!"
          }
        ];

    let questionsList = questions.map((item, i) => {
      let className = styles.answerClose;
      let icon = <FaAngleDown className="Icon question-icon" />;
      if (i === this.state.open) {
        className = styles.answerOpen;
        icon = <FaAngleUp className="Icon question-icon" />;
      }

      let classes = [styles.answer, className];

      return (
        <div key={`faq-${i}`} index={i}>
          <div className={styles.question} onClick={this.toggleQuestion(i)}>
            <div className={styles.sectionSubtitle}>{item.question}</div>
            <div>{icon}</div>
          </div>
          <div className={classes.join(" ")}>{item.answer}</div>
        </div>
      );
    });

    let button = null;
    if (this.props.button) {
      button = (
        <div className={styles.marginPBottom}>
          <a
            exact
            className={`${styles.redEvent} ${styles.left}`}
            rel="noopener noreferrer"
            target="_blank"
            href="http://moonshelp.zendesk.com"
          >
            <span>Ver todas las preguntas</span>
            <img className={styles.nextArrow} alt="" src={next} />
          </a>
        </div>
      );
    }

    let contact = null;
    if (this.props.contact) {
      contact = (
        <div>
          <HelpInfo />
        </div>
      );
    }

    let size = this.props.size === "large" ? styles.largeQuestions : null;

    return (
      <div className={`${styles.questions} ${size}`}>
        {questionsList}
        {button}
        {contact}
      </div>
    );
  }
}

export default Faq;
