import React, { useState, useEffect } from 'react'
import styles from './CookiesNotice.module.css'
import texts from './texts'

const CookiesNotice = () => {
  const [accepted, setAccepted] = useState(false)
  const [show, setShow] = useState(false);
  const pixels = 100 // number of pixels to scroll
  useEffect(() => {
    const onScroll = e => {
      if (e.target.documentElement.scrollTop > pixels){
        setShow(true)
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('acceptedCookies', 'yes')
    setAccepted(true)
  }
  if (accepted || localStorage.getItem('acceptedCookies')) {
    return null
  }
  if (show) {
    return (
      <div className={styles.modal}>
        <div className={styles.notice}>
          <div className={styles.noticeContainer}>
            <div className={styles.textNoticeContainer}>
              <p className={styles.title}>{texts.title}</p>
              <p className={styles.textNotice}>
                {texts.body}
              </p>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <div onClick={handleAcceptCookies} className={styles.button}>
              {texts.accept}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }

}

export default CookiesNotice
