import React, { Component } from "react";
import { Redirect } from "react-router";
import { FaMapMarkerAlt } from "react-icons/fa";

import { DAYS } from "../../util/appointment/Constants";
import imgTalk from "../../assets/images/appointment/talk.png";
import imgCelmoons from "../../assets/images/appointment/celmoons.png";
import imgTooth from "../../assets/images/appointment/tooth-1.png";
import imgGroup from "../../assets/images/appointment/group-6.png";

import "./appointmentDoneSuccess.css";
import { Step } from "./Step";
import AppointmentMap from "./AppointmentMap";
import apiB4A from "../../services/back4app/ApiBranches";
import { blackbox } from "../../services/appointment/api";

export default class AppointmentDoneSuccess extends Component {
  constructor() {
    super();
    this.state = {
      branch: ""
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async counrty => {
    const apiBranches = new apiB4A();
    const countryFilter = await apiBranches.getBranches(
      this.props.location.country
    );
    const branch = countryFilter.find(
      b => b.appointmentTypeID === this.props.location.data.appointmentTypeID
    );
    this.setState({ branch: branch });
  };

  render() {
    const data = this.props.location.data;
    const utcs = this.props.location.utcs;
    const name = `${data.firstName} ${data.lastName}`;
    if (data === undefined) {
      return <Redirect to={"agendar-mi-cita"} />;
    }

    blackbox.backbox.blackboxAppoinment(
      name,
      data.phone,
      data.email,
      utcs,
      "AppointmentCalendar"
    );
    const date = new Date(data.datetime);
    const dayName = DAYS[date.getDay()].toLowerCase();
    const dateFilter = data.date.split(" ");
    const dayAndMonth = `${dayName} ${dateFilter[0]} de ${dateFilter[1]}`;
    window.scrollTo(0, 0);

    return (
      <React.Fragment>
        <div className="separation"></div>
        <div className="d-flex justify-content-center">
          <div className="flex-column text-center">
            <p className="p-4 pronto">¡Nos vemos pronto!</p>
            <div className="p-4 d-flex justify-content-center">
              <p className="detail">
                {data.firstName}, tu Asesoría de Sonrisa está confirmada para el
                <b>{` ${dayAndMonth} a las ${data.time}.`}</b>
              </p>
            </div>
          </div>
        </div>

        <div className="d-flex flex-row info flex-wrap">
          <div className="size-fake"></div>
          <div className="size">
            <p>
              <b>Cuándo: </b> {dayAndMonth} <br />
              <b>Hora: </b>
              {data.time}
              <br />
              <b>Dónde:</b>{" "}
              <span className="where-appointment">{data.type}</span>
            </p>
          </div>

          {this.state.branch !== "" && (
            <div className="size">
              <FaMapMarkerAlt /> {data.location}
              <div className="map">
                <AppointmentMap
                  lat={this.state.branch.lat}
                  lng={this.state.branch.lng}
                  style={{
                    width: "100%",
                    height: "326px"
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <div className="d-flex justify-content-center">
          <div className="flex-column text-center">
            <div className="visita-content">
              <p className="visita">¿Qué pasará en tu visita?</p>
            </div>
            <div className="d-flex justify-content-center">
              <p className="steps">
                Te haremos una <b>Asesoría de Sonrisa y escaneo en 3D.</b> Así,
                tu ortodoncista moons diseñará un plan personalizado para ti,
                sin decenas de citas.{" "}
              </p>
            </div>

            <div className="separation"></div>

            <Step
              title={"1. Perfil clínico"}
              img={imgTalk}
              imgWidth={96}
              imgHeight={63}
              desc={
                "Tu asesor de sonrisa te hará unas preguntas para conocerte mejor."
              }
            />

            <div className="d-flex justify-content-center">
              <div className="line"></div>
            </div>

            <Step
              title={"2. Fotografías"}
              img={imgCelmoons}
              imgWidth={70}
              imgHeight={75}
              desc={"Tomaremos fotos intraorales y extraorales."}
            />

            <div className="d-flex justify-content-center">
              <div className="line"></div>
            </div>

            <Step
              title={"3. Escaneo en 3D"}
              img={imgTooth}
              imgWidth={58}
              imgHeight={64}
              desc={
                "Haremos un escaneo en 3D de tu sonrisa para obtener un modelo digital idéntico a tus dientes."
              }
            />

            <div className="d-flex justify-content-center">
              <div className="line"></div>
            </div>

            <Step
              title={"4. Plan de tratamiento"}
              img={imgGroup}
              imgWidth={74}
              imgHeight={75}
              desc={
                "¡Listo! Tu ortodoncista moons diseñará tu plan de tratamiento personalizado y lo enviaremos directo a tu correo."
              }
            />
          </div>
        </div>

        <div className="separation"></div>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          alt="cita agendada moons"
          src="https://www.facebook.com/tr?id=427318704548057&ev=Schedule&noscript=1"
        />
      </React.Fragment>
    );
  }
}
