export default {
  mx: {
    Home: {
      header: () => {
        return window.webpSupported
          ? [
              require("./assets/images/christmas-375.jpg"),
              require("./assets/images/christmas-960.jpg"),
              require("./assets/images/christmas-1500.jpg"),
              require("./assets/images/christmas-1500.jpg")
            ]
          : [
              require("./assets/images/christmas-375.jpg"),
              require("./assets/images/christmas-960.jpg"),
              require("./assets/images/christmas-1500.jpg"),
              require("./assets/images/christmas-1500.jpg")
            ];
      },
      steps: {
        step3: () => {
          return window.webpSupported
            ? require("./assets/images/Mexico/Home/empieza-tu-tratamiento-moons.webp")
            : require("./assets/images/Mexico/Home/empieza-tu-tratamiento-moons.jpg");
        }
      }
    },
    HowWorks: {
      header: () => {
        return window.webpSupported
          ? [
              require("./assets/images/Mexico/HowWorks/moons_comer_brackets-375.webp"),
              require("./assets/images/Mexico/HowWorks/moons_comer_brackets-960.webp"),
              require("./assets/images/Mexico/HowWorks/moons_comer_brackets-1500.webp"),
              require("./assets/images/Mexico/HowWorks/moons_comer_brackets-1800.webp")
            ]
          : [
              require("./assets/images/Mexico/HowWorks/moons_comer_brackets-375.jpg"),
              require("./assets/images/Mexico/HowWorks/moons_comer_brackets-960.jpg"),
              require("./assets/images/Mexico/HowWorks/moons_comer_brackets-1500.jpg"),
              require("./assets/images/Mexico/HowWorks/moons_comer_brackets-1800.jpg")
            ];
      },
      noCandidate: () => {
        return window.webpSupported
          ? [
              require("./assets/images/Mexico/HowWorks/moons_advanced-375.webp"),
              require("./assets/images/Mexico/HowWorks/moons_advanced-960.webp"),
              require("./assets/images/Mexico/HowWorks/moons_advanced-1500.webp"),
              require("./assets/images/Mexico/HowWorks/moons_advanced-1800.webp")
            ]
          : [
              require("./assets/images/Mexico/HowWorks/moons_advanced-375.jpg"),
              require("./assets/images/Mexico/HowWorks/moons_advanced-960.jpg"),
              require("./assets/images/Mexico/HowWorks/moons_advanced-1500.jpg"),
              require("./assets/images/Mexico/HowWorks/moons_advanced-1800.jpg")
            ];
      },
      faq: () => {
        return window.webpSupported
          ? [
              require("./assets/images/Mexico/HowWorks/moons_dudas_cita-375.webp"),
              require("./assets/images/Mexico/HowWorks/moons_dudas_cita-960.webp"),
              require("./assets/images/Mexico/HowWorks/moons_dudas_cita-1500.webp"),
              require("./assets/images/Mexico/HowWorks/moons_dudas_cita-1800.webp")
            ]
          : [
              require("./assets/images/Mexico/HowWorks/moons_dudas_cita-375.jpg"),
              require("./assets/images/Mexico/HowWorks/moons_dudas_cita-960.jpg"),
              require("./assets/images/Mexico/HowWorks/moons_dudas_cita-1500.jpg"),
              require("./assets/images/Mexico/HowWorks/moons_dudas_cita-1800.jpg")
            ];
      }
    },
    Pricing: {
      header: () => {
        return window.webpSupported
          ? [
              require("./assets/images/Mexico/Pricing/moons_cuanto_cuestan-375.webp"),
              require("./assets/images/Mexico/Pricing/moons_cuanto_cuestan-960.webp"),
              require("./assets/images/Mexico/Pricing/moons_cuanto_cuestan-1500.webp"),
              require("./assets/images/Mexico/Pricing/moons_cuanto_cuestan-1800.webp")
            ]
          : [
              require("./assets/images/Mexico/Pricing/moons_cuanto_cuestan-375.jpg"),
              require("./assets/images/Mexico/Pricing/moons_cuanto_cuestan-960.jpg"),
              require("./assets/images/Mexico/Pricing/moons_cuanto_cuestan-1500.jpg"),
              require("./assets/images/Mexico/Pricing/moons_cuanto_cuestan-1800.jpg")
            ];
      },
      faq: () => {
        return window.webpSupported
          ? [
              require("./assets/images/Mexico/Pricing/faq-375.webp"),
              require("./assets/images/Mexico/Pricing/faq-960.webp"),
              require("./assets/images/Mexico/Pricing/faq-1500.webp"),
              require("./assets/images/Mexico/Pricing/faq-1800.webp")
            ]
          : [
              require("./assets/images/Mexico/Pricing/faq-375.jpg"),
              require("./assets/images/Mexico/Pricing/faq-960.jpg"),
              require("./assets/images/Mexico/Pricing/faq-1500.jpg"),
              require("./assets/images/Mexico/Pricing/faq-1800.jpg")
            ];
      }
    },
    Office: {
      branches: () => {
        return window.webpSupported
          ? require("./assets/images/Mexico/Office/office-960.webp")
          : require("./assets/images/Mexico/Office/office-960.jpg");
      },
      branchDetail: () => {
        return window.webpSupported
          ? [
              require("./assets/images/Mexico/Office/branch-detail-375.webp"),
              require("./assets/images/Mexico/Office/branch-detail-960.webp"),
              require("./assets/images/Mexico/Office/branch-detail-1500.webp"),
              require("./assets/images/Mexico/Office/branch-detail-1800.webp")
            ]
          : [
              require("./assets/images/Mexico/Office/branch-detail-375.jpg"),
              require("./assets/images/Mexico/Office/branch-detail-960.jpg"),
              require("./assets/images/Mexico/Office/branch-detail-1500.jpg"),
              require("./assets/images/Mexico/Office/branch-detail-1800.jpg")
            ];
      }
    },
    Reviews: {
      header: () => {
        return window.webpSupported
          ? [
              require("./assets/images/Mexico/Reviews/moons_ortodoncia_invisible-375.webp"),
              require("./assets/images/Mexico/Reviews/moons_ortodoncia_invisible-960.webp"),
              require("./assets/images/Mexico/Reviews/moons_ortodoncia_invisible-1500.webp"),
              require("./assets/images/Mexico/Reviews/moons_ortodoncia_invisible-1800.webp")
            ]
          : [
              require("./assets/images/Mexico/Reviews/moons_ortodoncia_invisible-375.jpg"),
              require("./assets/images/Mexico/Reviews/moons_ortodoncia_invisible-960.jpg"),
              require("./assets/images/Mexico/Reviews/moons_ortodoncia_invisible-1500.jpg"),
              require("./assets/images/Mexico/Reviews/moons_ortodoncia_invisible-1800.jpg")
            ];
      }
    }
  },

  co: {
    Home: {
      header: () => {
        return window.webpSupported
          ? [
              require("./assets/images/Colombia/Home/header-375.webp"),
              require("./assets/images/Colombia/Home/header-960.webp"),
              require("./assets/images/Colombia/Home/header-1500.webp"),
              require("./assets/images/Colombia/Home/header-1800.webp")
            ]
          : [
              require("./assets/images/Colombia/Home/header-375.jpg"),
              require("./assets/images/Colombia/Home/header-960.jpg"),
              require("./assets/images/Colombia/Home/header-1500.jpg"),
              require("./assets/images/Colombia/Home/header-1800.jpg")
            ];
      },
      steps: {
        step3: () => {
          return window.webpSupported
            ? require("./assets/images/Colombia/Home/step-3.webp")
            : require("./assets/images/Colombia/Home/step-3.jpg");
        }
      }
    },
    HowWorks: {
      header: () => {
        return window.webpSupported
          ? [
              require("./assets/images/Colombia/HowWorks/header-375.webp"),
              require("./assets/images/Colombia/HowWorks/header-960.webp"),
              require("./assets/images/Colombia/HowWorks/header-1500.webp"),
              require("./assets/images/Colombia/HowWorks/header-1800.webp")
            ]
          : [
              require("./assets/images/Colombia/HowWorks/header-375.jpg"),
              require("./assets/images/Colombia/HowWorks/header-960.jpg"),
              require("./assets/images/Colombia/HowWorks/header-1500.jpg"),
              require("./assets/images/Colombia/HowWorks/header-1800.jpg")
            ];
      },
      noCandidate: () => {
        return window.webpSupported
          ? [
              require("./assets/images/Colombia/HowWorks/no-candidate-375.webp"),
              require("./assets/images/Colombia/HowWorks/no-candidate-960.webp"),
              require("./assets/images/Colombia/HowWorks/no-candidate-1500.webp"),
              require("./assets/images/Colombia/HowWorks/no-candidate-1800.webp")
            ]
          : [
              require("./assets/images/Colombia/HowWorks/no-candidate-375.jpg"),
              require("./assets/images/Colombia/HowWorks/no-candidate-960.jpg"),
              require("./assets/images/Colombia/HowWorks/no-candidate-1500.jpg"),
              require("./assets/images/Colombia/HowWorks/no-candidate-1800.jpg")
            ];
      },
      faq: () => {
        return window.webpSupported
          ? [
              require("./assets/images/Colombia/HowWorks/faq-375.webp"),
              require("./assets/images/Colombia/HowWorks/faq-960.webp"),
              require("./assets/images/Colombia/HowWorks/faq-1500.webp"),
              require("./assets/images/Colombia/HowWorks/faq-1800.webp")
            ]
          : [
              require("./assets/images/Colombia/HowWorks/faq-375.jpg"),
              require("./assets/images/Colombia/HowWorks/faq-960.jpg"),
              require("./assets/images/Colombia/HowWorks/faq-1500.jpg"),
              require("./assets/images/Colombia/HowWorks/faq-1800.jpg")
            ];
      }
    },
    Pricing: {
      header: () => {
        return window.webpSupported
          ? [
              require("./assets/images/Colombia/Pricing/header-375.webp"),
              require("./assets/images/Colombia/Pricing/header-960.webp"),
              require("./assets/images/Colombia/Pricing/header-1500.webp"),
              require("./assets/images/Colombia/Pricing/header-1800.webp")
            ]
          : [
              require("./assets/images/Colombia/Pricing/header-375.jpg"),
              require("./assets/images/Colombia/Pricing/header-960.jpg"),
              require("./assets/images/Colombia/Pricing/header-1500.jpg"),
              require("./assets/images/Colombia/Pricing/header-1800.jpg")
            ];
      },
      faq: () => {
        return window.webpSupported
          ? [
              require("./assets/images/Colombia/Pricing/faq-375.webp"),
              require("./assets/images/Colombia/Pricing/faq-960.webp"),
              require("./assets/images/Colombia/Pricing/faq-1500.webp"),
              require("./assets/images/Colombia/Pricing/faq-1800.webp")
            ]
          : [
              require("./assets/images/Colombia/Pricing/faq-375.jpg"),
              require("./assets/images/Colombia/Pricing/faq-960.jpg"),
              require("./assets/images/Colombia/Pricing/faq-1500.jpg"),
              require("./assets/images/Colombia/Pricing/faq-1800.jpg")
            ];
      }
    },
    Office: {
      branches: () => {
        return window.webpSupported
          ? require("./assets/images/Colombia/Office/office-960.webp")
          : require("./assets/images/Colombia/Office/office-960.jpg");
      },
      branchDetail: () => {
        return window.webpSupported
          ? [
              require("./assets/images/Colombia/Office/branch-detail-375.webp"),
              require("./assets/images/Colombia/Office/branch-detail-960.webp"),
              require("./assets/images/Colombia/Office/branch-detail-1500.webp"),
              require("./assets/images/Colombia/Office/branch-detail-1800.webp")
            ]
          : [
              require("./assets/images/Colombia/Office/branch-detail-375.jpg"),
              require("./assets/images/Colombia/Office/branch-detail-960.jpg"),
              require("./assets/images/Colombia/Office/branch-detail-1500.jpg"),
              require("./assets/images/Colombia/Office/branch-detail-1800.jpg")
            ];
      }
    },
    Reviews: {
      header: () => {
        return window.webpSupported
          ? [
              require("./assets/images/Colombia/Reviews/header-375.webp"),
              require("./assets/images/Colombia/Reviews/header-960.webp"),
              require("./assets/images/Colombia/Reviews/header-1500.webp"),
              require("./assets/images/Colombia/Reviews/header-1800.webp")
            ]
          : [
              require("./assets/images/Colombia/Reviews/header-375.jpg"),
              require("./assets/images/Colombia/Reviews/header-960.jpg"),
              require("./assets/images/Colombia/Reviews/header-1500.jpg"),
              require("./assets/images/Colombia/Reviews/header-1800.jpg")
            ];
      }
    }
  },

  universal: {
    Home: {
      steps: {
        step1: () => {
          return window.webpSupported
            ? require("./assets/images/Universal/Home/moons_escaneo_3D.webp")
            : require("./assets/images/Universal/Home/moons_escaneo_3D.png");
        },
        step2: () => {
          return window.webpSupported
            ? require("./assets/images/Universal/Home/moons_plan_de_tratamiento.webp")
            : require("./assets/images/Universal/Home/moons_plan_de_tratamiento.jpg");
        }
      }
    }
  }
};
