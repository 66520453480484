import React from "react";
import styles from "./Prices.module.css";

const Prices = (props) => {
  const animated = props.animated;
  return (
    <div className={styles.flexPrice}>
      <div className={styles.flexLeft}>
        <div className={styles.contentPrices}>
          <div className="small-title">Una inversión que cambiará tu vida</div>
          {props.pricesDesc}
        </div>
      </div>
      <div className={styles.flexRight}>
        <div className={styles.flexChart}>
          <div
            className={`${styles.flexChart1} ${
              animated === "" ? "" : styles.animated
            }`}
          ></div>
          <div>
            <div>moons</div>
            <div>
              Desde $19,990
              <div className={styles.IVA}>IVA incuido</div>
            </div>
          </div>
        </div>
        <div className={styles.flexChart}>
          <div
            className={`${styles.flexChart2} ${
              animated === "" ? "" : styles.animated
            }`}
          ></div>
          <div>
            <div>Tratamiento convencional</div>
            <div>{props.bracketsPrice}</div>
          </div>
        </div>
        <div className={`${styles.flexChart} ${styles.noMarginBottom}`}>
          <div
            className={`${styles.flexChart3} ${
              animated === "" ? "" : styles.animated
            }`}
          ></div>
          <div>
            <div>Otros</div>
            <div>{props.invisalignPrice}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prices;
