import React, { Component } from "react";
import imgMoonsLoader from "../../../assets/images/moon-loader.svg";
import "../LoaderPage/loaderPage.css";

export default class LoaderPage extends Component {
  render() {
    return (
      <div className="modal">
        <div className="heartbeat">
          <img height="100" width="100" src={imgMoonsLoader} alt="Loader page"/>
        </div>
      </div>
    );
  }
}
