import React from "react";
import styles from "./HelpInfo.module.css";

const helpInfo = () => {
  return (
    <div className={styles.helpInfo}>
      <div className={styles.helpInfoLogo}></div>
      <div className={styles.regularText}>
        ¿Necesitas ayuda personal?
        <br />
        Llámanos al{" "}
        <a
          className={styles.linkWhatsapp}
          rel="noopener noreferrer"
          target="_blank"
          href="tel:+525585265887"
        >
          55 8526 5887
        </a>
      </div>
    </div>
  );
};

export default helpInfo;
