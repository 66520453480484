
import React from 'react';
import './SocialLinks.css';
import facebookWhite from '../../assets/images/facebook-white.svg';
import instagramWhite from '../../assets/images/instagram-white.svg';
import twitterWhite from '../../assets/images/twitter-white.svg';
import youtubeWhite from '../../assets/images/youtube-white.svg';

import facebookBlack from '../../assets/images/facebook-black.svg';
import instagramBlack from '../../assets/images/instagram-black.svg';
import twitterBlack from '../../assets/images/twitter-black.svg';
import youtubeBlack from '../../assets/images/youtube-black.svg';

const socialLinks = (props)=>{

    const facebookIcon = props.blackIcons ? facebookBlack : facebookWhite;
    const twitterIcon = props.blackIcons ? twitterBlack : twitterWhite;
    const instagramIcon = props.blackIcons ? instagramBlack : instagramWhite;
    const youtubeIcon = props.blackIcons ? youtubeBlack : youtubeWhite;
    const instagramLink = props.country === 'mx' ? 'https://www.instagram.com/welovemoons' : 'https://www.instagram.com/welovemoons.co/';
    const facebookLink = props.country === 'mx' ? "https://www.facebook.com/welovemoons" : "https://www.facebook.com/welovemoons.co";

    return(
        <div className="social-containers">
            <div className="social-icon">
                <a rel="noopener noreferrer" target="_blank" href={facebookLink}>
                    <img alt="facebook"  src={facebookIcon}/>
                </a>
            </div>
            <div className="social-icon">
                <a rel="noopener noreferrer" target="_blank" href={instagramLink}>
                    <img alt="instagram"  src={instagramIcon}/>
                </a>
            </div>
            <div className="social-icon">
                <a rel="noopener noreferrer" target="_blank" href="https://www.twitter.com/welovemoons">
                    <img alt="twitter"  src={twitterIcon}/>
                </a>
            </div>
            <div className="social-icon">
                <a rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/channel/UCd7_nNxTedFFzxRVQRCg3cw">
                    <img alt="youtube"  src={youtubeIcon}/>
                </a>
            </div>
        </div>
    );
}

export default socialLinks;