import React, { Component } from "react";
import { DAYS, MONTHS } from "../../util/appointment/Constants";
import { dateAcuity } from "../../util/appointment/Utils";
import { api } from "../../services/appointment/api";
import AppointmentMap from "./AppointmentMap";
import { scrollToDiv } from "../../util/Scroll";
import apiB4A from "../../services/back4app/ApiBranches";

export const loadCalendar = async (country, typeId, types, isRequiredMap) => {
  document.getElementById("dates").innerHTML = "";

  if (typeId === "noValido") {
    document.getElementById("calendar").innerHTML = "";
    return false;
  }
  const apiBranches = new apiB4A();
  const countryFilter = await apiBranches.getBranches(country);
  const branch = countryFilter.find(
    b => `${b.appointmentTypeID}` === `${typeId}`
  );

  if (branch === undefined) {
    document.getElementById("calendar").classList.add("hidden");
    return false;
  }
  const typeSelected = types.find(f => `${f.id}`.includes(typeId));
  const calendars = await api.acuity.calendars();
  const calendar = calendars.find(c =>
    `${c.id}`.includes(typeSelected.calendarIDs[0])
  );

  if (isRequiredMap) {
    document.getElementById("ubication").classList.remove("hidden");
    document.getElementById("location").innerHTML = calendar.location;
  }

  let dates = await api.acuity.dates(typeId, dateAcuity(false), calendar.id);

  if (dates.length <= 7) {
    dates = dates.concat(
      await api.acuity.dates(typeId, dateAcuity(true), calendar.id)
    );
  }
  if (dates.length > 7) {
    dates = dates.splice(0, 6);
  }

  dates.forEach(async d => {
    const date = new Date(d.date);
    date.setDate(date.getDate() + 1);
    const dayName = DAYS[date.getDay()];
    const monthName = MONTHS[date.getMonth()];

    const para = document.createElement("div");
    para.className = "size-dates px-3";

    const br = document.createElement("br");

    const day = document.createElement("label");
    day.innerHTML = dayName;
    day.className = "text";

    const month = document.createElement("label");
    month.innerHTML = monthName + " " + date.getDate();
    month.className = "text";

    para.appendChild(day);
    para.appendChild(br);
    para.appendChild(month);

    document.getElementById("dates").appendChild(para);

    const times = await api.acuity.times(typeId, d.date, calendar.id);
    let time;

    times.forEach(t => {
      const button = document.createElement("button");
      button.innerHTML = `${t.time}`.substring(11, 16);
      button.value = t.time;
      button.className = "button-cita";
      button.onclick = e => {
        const selecteds = document.querySelectorAll(".button-selected");
        selecteds.forEach(s => {
          if (!`${s}`.includes(time)) {
            s.classList.add("button-cita");
            s.classList.toggle("button-selected");
          }
        });
        time = e.target.value;
        document.getElementById("contact").classList.remove("hidden");
        e.target.className = "button-selected";
        scrollToDiv("contact");
      };

      const separation = document.createElement("div");
      separation.className = "space-dates";

      para.appendChild(separation);
      para.appendChild(button);
    });
    const separation = document.createElement("div");
    separation.className = "space-dates";
    para.appendChild(separation);
  });

  document.getElementById("calendar").classList.remove("hidden");

  return branch;
};

export const getTime = () => {
  const selected = document.querySelectorAll(".button-selected");
  return selected[0].value;
};

export class CalendarForm extends Component {
  handlePhone = evt => {
    if (
      (evt.which !== 8 && evt.which !== 0 && evt.which < 48) ||
      evt.which > 57
    ) {
      evt.preventDefault();
    }
  };

  render() {
    let posx = 1;
    return (
      <React.Fragment>
        <div
          id="calendar"
          className={`hidden ${this.props.compact ? "sticky" : ""}`}
        >
          <div className="flex-container flex-column">
            <label className="title-2">¿Qué día y a qué hora?</label>
            <div
              className={`justify-content-end ${
                this.props.compact ? "compact" : ""
              }`}
            >
              <button
                className="button-more-dates"
                onClick={() => {
                  document.getElementById("dates").scroll({
                    left: 300 * posx,
                    behavior: "smooth"
                  });
                  posx++;
                }}
              >
                más días >
              </button>
            </div>
            <div className="flex-container ">
              <div id="dates" className="dates-container dates"></div>
            </div>
          </div>
          <div id="contact" className="hidden">
            <p className={`title-2 ${this.props.compact ? " compact" : ""}`}>
              ¿Cómo te contactamos?
            </p>
            <br />
            <div className="flex-container">
              <form onSubmit={this.props.handleSubmit}>
                <div className="form-group">
                  <label className="text">
                    <b>Nombre</b>
                  </label>
                  <br />
                  <input
                    onChange={this.props.handleOnChangeText}
                    id="name"
                    placeholder="Nombre y apellidos"
                    className="border-input border-input-size"
                  />
                </div>
                <div className="form-group">
                  <label className="text">
                    <b>Teléfono</b>
                  </label>
                  <br />
                  <input
                    onChange={this.props.handleOnChangeText}
                    onKeyPress={this.handlePhone}
                    id="phone"
                    maxLength="10"
                    placeholder="Tu número de WhatsApp"
                    className="border-input border-input-size"
                  />
                </div>
                <div className="form-group">
                  <label className="text">
                    <b>Correo electrónico</b>
                  </label>
                  <br />
                  <input
                    type="email"
                    onChange={this.props.handleOnChangeText}
                    id="email"
                    placeholder="Aquí recibirás la confirmación de tu cita"
                    className="border-input border-input-size"
                  />
                </div>
                <div className="error-message">
                  <label>{this.props.error}</label>
                </div>
                <div className="separation"></div>
                <div className="flex-container">
                  <button
                    id="button-agendar"
                    className="button-agendar"
                    disabled
                  >
                    AGENDAR MI CITA
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div id="ubication" className="flex-wrap padding-x hidden">
          <p className="title-2">Ubicación</p>
          <p id="location" className="text"></p>
        </div>

        {this.props.lat > 0 && (
          <div className="flex-container">
            <div className="map-calendar">
              <AppointmentMap
                lat={this.props.lat}
                lng={this.props.lng}
                style={{
                  position: "relative",
                  width: "100%",
                  height: "326px"
                }}
              />
            </div>
          </div>
        )}

        {this.props.ubicationSpace && <div className="separation"></div>}
        <div className="separation"></div>

        <div className="flex-container"></div>
      </React.Fragment>
    );
  }
}
