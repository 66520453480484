import React from "react";
import next from "../../assets/images/next.svg";
import { NavLink } from "react-router-dom";

import styles from "./NextButton.module.css";

const nextButton = props => {
  return (
    <NavLink to={props.linkTo} className={props.classButton}>
      <span>{props.label}</span>
      <img className={styles.nextArrow} alt="" src={next} />
    </NavLink>
  );
};

export default nextButton;
