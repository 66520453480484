import React from "react";
import styles from "./Whatsapp.module.css";

const whatsapp = props => {
  let handleEvent = () => {
  };

  if (props.countryData) {
    let code = props.countryData.code;
    let whatsapp = props.countryData.whatsapp
      ? props.countryData.whatsapp.replace(/\s+/g, "")
      : props.countryData.whatsapp;

    return (
      <a
        onClick={handleEvent}
        className={styles.underlineLink}
        rel="noopener noreferrer"
        target="_blank"
        href={`https://api.whatsapp.com/send?phone=${code}${whatsapp}`}
      >
        {props.children}
      </a>
    );
  } else {
    return null;
  }
};

export default whatsapp;
