import React, { Component } from "react";
import styles from "./MainSection.module.css";
class mainSection extends Component {
  state = {
    classBackground: styles.initial
  };

  interval = false;

  componentDidMount() {
    /* this.interval = setInterval(() => {
      if (this.state.classBackground === styles.initial) {
        this.setState({ classBackground: styles.secondary });
      } else if (this.state.classBackground === styles.secondary) {
        this.setState({ classBackground: styles.third });
      } else if (this.state.classBackground === styles.third) {
        this.setState({ classBackground: styles.initial });
      }
    }, 1000); */
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    let imageDirection =
      this.props.imagePosition === styles.left ? styles.left : styles.right;
    let textPosition =
      this.props.imagePosition === styles.left ? styles.right : styles.left;
    let image = this.props.image ? this.props.image : null;
    let textStyle = this.props.textStyle ? this.props.textStyle : null;
    let extraIcon = this.props.extraIcon ? (
      <div className={this.props.extraIcon}></div>
    ) : null;
    let webpClass = this.props.webp ? styles.webp : styles.noWebp;
    let imageContent = image ? (
      <div className={`${styles.mainBackgroundDinamic} ${imageDirection}`}>
        {extraIcon}
        <div
          className={`${styles.imageContainerDinamic} ${styles.spriteDynamic} ${this.props.sprite} ${webpClass} ${this.state.classBackground}`}
        >
          <img
            className={styles.sprite}
            alt="section-home"
            src={image[0]}
            srcSet={`${image[0]} 375w, ${image[1]} 960w, ${image[2]} 1500w, ${image[3]} 1800w`}
          />
        </div>
      </div>
    ) : null;

    // let heightAuto = image ? null : 'height-auto';

    return (
      <div className={styles.sectionContainer}>
        {imageContent}
        <div className={`${styles.mainFront}`}>
          <div
            className={`${styles.sectionColumns} ${textPosition} ${textStyle}`}
          >
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default mainSection;
