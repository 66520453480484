import React from 'react';
import {withRouter, useLocation, useHistory} from 'react-router-dom';

import styles from './DeepLinks.module.css';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DeepLinks = () => {
  const appURL = useQuery().get('url');
  const history = useHistory();

  if(appURL) {
    if (
      // Solo redirigir automáticamente si es Android o iOS
      navigator.userAgent.toLowerCase().indexOf("iphone") > -1 ||
      navigator.userAgent.toLowerCase().indexOf("android") > -1
    ) {
      window.location = appURL;
    }
  } else {
    // Redirigir a Home en caso de que no haya ningún link a donde ir
    history.push('/');
  }
  return (
    <div className={styles.main}>
      <div className={styles.text}>
        <p className={styles.title}>Contenido exclusivo de nuestra App.</p>
        <p className={styles.info}>Descárgala para poder acceder a el.</p>
        <div className={styles.links}>
          <a href="/#no-sirvo-aun">iOS APP</a>
          <a href="/#no-sirvo-aun">Android APP</a>
        </div>
      </div>
    </div>
  )
}

export default withRouter(DeepLinks);
