import React from "react";
import oxxoGray from "../../assets/images/moons_pago_efectivo_oxxo.svg";
import visaGray from "../../assets/images/moons_pago_tarjeta_debito.svg";
import amexGray from "../../assets/images/moons_pago_amex.svg";
import speiGray from "../../assets/images/moons_pago_SPEI_transferencia.svg";
import mastercardGray from "../../assets/images/moons_pago_tarjeta_credito.svg";
import styles from "./PayInfo.module.css";
import empaqueReg from "../../assets/images/moons_kit_que_incluye.jpg";
import empaqueWebp from "../../assets/images/webp/moons_kit_que_incluye.webp";

const payInfo = props => {
  let country = props.country;

  let list = (
    <ul className={`${styles.sectionList} ${styles.priceList}`}>
      <li>Diagnóstico y escaneo en 3D</li>
      <li>Diseño de tu plan ortodóntico personalizado</li>
      <li>Video en 3D de tus resultados</li>
      <li>Todos los moons de tu tratamiento</li>
      <li>Seguimiento remoto de tu ortodoncista moons</li>
    </ul>
  );

  let paymentMethods = (
    <div>
      <div className={`${styles.sectionBottom25} ${styles.textSmallNotes}`}>
        Aceptamos tarjetas de crédito, débito, transferencia interbancaria y
        efectivo.
      </div>
      <div className={`${styles.paymentMethods} ${styles.methodsLogos}`}>
        <div>
          <img
            className={styles.logoCardXs2}
            alt="moons pago tarjeta debito"
            src={visaGray}
          />
        </div>
        <div>
          <img
            className={styles.logoCardXs}
            alt="moons pago tarjeta credito"
            src={mastercardGray}
          />
        </div>
        <div>
          <img
            className={styles.logoCardXs}
            alt="moons pago amex"
            src={amexGray}
          />
        </div>
        <div>
          <img
            className={styles.logoCardXs}
            alt="moons pago SPEI transferencia"
            src={speiGray}
          />
        </div>
        <div>
          <img
            className={styles.logoCardXs2}
            alt="moons pago efectivo oxxo"
            src={oxxoGray}
          />
        </div>
      </div>
    </div>
  );

  if (country === "co") {
    list = (
      <div className={styles.payInfo}>
        <ul className={`${styles.sectionList} ${styles.priceList}`}>
          <li>Diagnóstico y escaneo en 3D</li>
          <li>Diseño de tu plan ortodóntico personalizado</li>
          <li>Video en 3D de tus resultados</li>
          <li>Todos los moons de tu tratamiento</li>
          <li>Seguimiento remoto de tu ortodoncista moons</li>
        </ul>
      </div>
    );
    paymentMethods = null;
  }

  let empaque = props.webp ? empaqueWebp : empaqueReg;

  return (
    <div className={styles.payInfoContainer}>
      <div className={styles.payInfoDetails}>
        <div className={`${styles.pricingTitle} ${styles.mediumTitle}`}>
          ¿Qué incluye tu tratamiento?
        </div>
        <div
          className={`${styles.sectionSpaceSmall} ${styles.relative} ${styles.payInfo}`}
        >
          <div className={`${styles.overFlowAuto} ${styles.sectionBottom25}`}>
            <div
              className={`${styles.wowIconLargeMobile} ${styles.hideDesktop}`}
            ></div>
          </div>
          <div className={styles.alignCenter}>{list}</div>
          {paymentMethods}
        </div>
      </div>
      <div className={styles.kitImage}>
        <img alt="moons kit que incluye" src={empaque} />
      </div>
    </div>
  );
};

export default payInfo;
