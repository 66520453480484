import React, { Component } from "react";

import "../LoaderSection/loaderSection.css";

export default class LoaderSection extends Component {
  render() {
    return (
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }
}
