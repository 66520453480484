import React from "react";
import styles from "./Home.module.css";

export default {
  mx: {
    Header: {
      title: (
        <div>
          <span>LIBERA TU SONRISA SIN SALIR DE CASA</span>
        </div>
      ),
      subtitle: (
        <div>
          Endereza tus dientes con alineación{" "}
          <br className={styles.breakDesktop} />
          dental invisible moons. Más rápido que los métodos{" "}
          <br className={styles.breakDesktop} />
          convencionales, sin metal y hasta 65% más económico.
        </div>
      ),
    },
    Steps: {
      step1Title: "1. Haz tus impresiones y mira tus resultados",
      step1Description:
        "Con ellas hacemos un modelo 3D con el que tu Ortodoncista moons diseña tu nueva sonrisa. Antes de empezar, sabes cómo quedarás y en cuánto tiempo.",
      step2Title: "2. Cambia tus moons desde casa",
      step2Description:
        "Recibe todos los moons para tu tratamiento en un solo kit. Cámbialos cada 2 semanas y obtén seguimiento remoto de tu Ortodoncista.",
      step3Title: "3. ¡Sonríe a tu nueva vida!",
      step3Description:
        "En sólo unos meses terminarás tu tratamiento de ortodoncia. ¡Disfruta tu nueva sonrisa más hermosa y alineada!",
    },
    Prices: {
      description: (
        <p className={`${styles.regularText} ${styles.marginBottom30}`}>
          Cambia tu sonrisa y paga hasta 65% menos que con la ortodoncia
          tradicional.
        </p>
      ),
      moonsPrice: "Desde %s $%s",
      bracketsPrice: "+$30,000",
      invisalignPrice: "+$60,000",
    },
    HomeForm: {
      subtitle: "Por el momento, los moons son solo para mayores de edad.",
      cta: "Enviar",
    },
    Reviews: {
      title: "Miles de personas ya aman sus moons",
    },
  },
  co: {
    Header: {
      title: (
        <div>
          <span>
            UNA SONRISA <br /> INCREÍBLE SIN
            <br /> BRACKETS
          </span>
        </div>
      ),
      subtitle: (
        <div>
          Descubre la forma más fácil de tener dientes
          <br className={styles.breakDesktop} />
          derechos con los{" "}
          <span className={styles.emphasize}>
            alineadores invisibles moons:&nbsp;
          </span>
          <br className={styles.breakDesktop} />
          sin alambres, sin líos y en 6 meses promedio.
        </div>
      ),
    },
    Steps: {
      step1Title: "1. Pide tu cita de valoración",
      step1Description:
        "Agenda tu Asesoría de Sonrisa. Haremos tu diagnóstico y escaneo en 3D de tus dientes.",
      step2Title: "2. Mira tus resultados",
      step2Description:
        "Tu ortodoncista moons diseña tu tratamiento a medida y te muestra un video con tus resultados y duración.",
      step3Title: "3. Empieza tu tratamiento",
      step3Description:
        "Recibe tus moons hasta tu puerta. Cambia de paso cada 2 semanas ¡y transforma tu sonrisa!",
    },
    Prices: {
      description: (
        <p className={styles.regularText}>
          Los moons son hasta 65 % menos costosos que otras marcas de
          alineadores y la ortodoncia tradicional.
        </p>
      ),
      moonsPrice: "%s en hasta 12 cuotas",
      bracketsPrice: "+$4.000.000",
      invisalignPrice: "+$5.000.000",
    },
    HomeForm: {
      subtitle:
        "Descúbrelo respondiendo este cuestionario. El tratamiento se recomienda sólo para mayores de edad.",
      cta: "Ver si soy candidato",
    },
    Reviews: {
      title: "¡Miles de personas ya usan moons!",
    },
  },
};
