import React from "react";
import Faq from "../Faq/Faq";
import WhatsApp from "../Whatsapp/Whatsapp";
import { NavLink } from "react-router-dom";
import styles from "./FaqCitas.module.css";

const faqCitas = props => {
  let size = props.size ? props.size : null;
  let button = props.button ? props.button : null;
  let countryData = props.countryData;

  let asesoriaSonrisa = [
    {
      question: "¿Dónde están los estudios?",
      answer: (
        <div>
          ¡Estamos trabajando para tener nuestro primer estudio! Por ahora,
          agenda tu Asesoría de Sonrisa en uno de nuestros centros{" "}
          <NavLink className={styles.underlineLink} to="/agendar-mi-cita">
            centros radiológicos afiliados
          </NavLink>
          . Por ahora, nosotros absorbemos el costo de tu visita para que sea
          completamente gratis.
        </div>
      )
    },
    {
      question: "¿Cómo hago una cita?",
      answer: (
        <div>
          Escríbenos al{" "}
          <WhatsApp countryData={countryData}>WhatsApp</WhatsApp>
        </div>
      )
    },
    {
      question: "¿Puedo ir sin hacer una cita?",
      answer:
        "No te lo recomendamos. Para que te podamos atender como mereces, prepárate con tiempo."
    },
    {
      question: "¿Cuánto tiempo dura la Asesoría de Sonrisa?",
      answer:
        "Dura entre 30 y 45 minutos. Aprovecha tu visita para aclarar todas las dudas que tengas en persona. Además, tenemos un kit de muestra para que conozcas la textura y transparencia de los moons."
    }
  ];

  return <Faq size={size} button={button} questions={asesoriaSonrisa} />;
};

export default faqCitas;
