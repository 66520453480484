import React, { Component } from "react";
import { Redirect } from "react-router";
import PhoneNumber from "../PhoneNumber/PhoneNumber";
import AppointmentMap from "../Appointment/AppointmentMap";
import SectionColumns from "../../Components/SectionColumns/SectionColumns";
import FaqCitas from "../../Components/FaqCitas/FaqCitas";
import dario_alineador_375 from "../../assets/images/dario_alineador_375.jpg";
import dario_alineador_960 from "../../assets/images/dario_alineador_960.jpg";
import dario_alineador_1500 from "../../assets/images/dario_alineador_1500.jpg";
import dario_alineador_1800 from "../../assets/images/dario_alineador_1800.jpg";
import {
  CalendarForm,
  loadCalendar,
  getTime
} from "../Appointment/CalendarForm";
import { api } from "../../services/appointment/api";
import LoaderSection from "../Loader/LoaderSection/LoaderSection";
import LoaderPage from "../Loader/LoaderPage/LoaderPage";
import "./BranchDetail.css";
import "./Office.css";
import "../Appointment/appointment.css";
import apiB4A from "../../services/back4app/ApiBranches";

export default class BranchDetail extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      branch: "",
      appointmentTypeId: undefined
    };
  }

  getData = async () => {
    const { centro } = this.props.match.params;
    const b4a = new apiB4A();
    const object = await b4a.getBranchByCentro(centro);
    const branch = object[0];
    const country = branch.country;
    if (branch === undefined || branch === null) {
      const branchStorage = window.localStorage.getItem("branch");
      const branch = JSON.parse(branchStorage);
      const country = window.localStorage.getItem("countryCalendar");
      return { branch, country };
    }
    window.localStorage.setItem("branch", JSON.stringify(branch));
    window.localStorage.setItem("countryCalendar", country);
    return { branch, country };
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    const { branch, country } = await this.getData();
    this.setState({ branch: branch });
    const typesCalendar = await api.acuity.appointmentTypes();

    await loadCalendar(country, branch.appointmentTypeID, typesCalendar, false);
    this.setState({ loading: false });
  };

  handleOnChangeText = e => {
    const name = document.getElementById("name").value;
    const phone = document.getElementById("phone").value;
    const email = document.getElementById("email").value;

    if (name.length > 3 && phone.length === 10 && email.length > 5) {
      this.setState({ name: name, phone: phone, email: email });
      document.getElementById("button-agendar").disabled = false;
    }
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ loader: true });
    const appointment = await api.acuity.appointments(
      getTime(),
      this.state.branch.appointmentTypeID,
      this.state.name,
      this.state.email,
      this.state.phone
    );

    if (appointment.email === this.state.email) {
      this.setState({ redirect: true, appointment: appointment });
    }
    if (appointment.error === "required_last_name") {
      this.setState({
        loader: false,
        error: "Es requerido ingresar al menos un apellido"
      });
    }
  };

  render() {
    if (this.state.redirect) {
      const { country } = this.getData();

      return (
        <Redirect
          to={{
            pathname: "/cita-moons-agendada",
            data: this.state.appointment,
            country: country
          }}
        />
      );
    }

    if (this.state.branch === "" || this.state.branch === undefined) {
      return <LoaderPage />;
    }

    const existPhone =
      this.state.branch.phone !== "" && this.state.branch.phone !== undefined;

    return (
      <>
        <div className="center-container margin-top-space">
          <div className="section-bottom-50">
            <div className="xl-title margin-top-title">
              {this.state.branch.centro}
            </div>
          </div>
          <div className="section-space-xs">
            <div className="office-columns">
              <div className="office-column">
                <div className="youtube-video-container section-bottom-50">
                  <iframe
                    title="¿Que son los moons?"
                    src="https://player.vimeo.com/video/379898278"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
                <div className="regular-text section-bottom-25">
                  {`${
                    this.state.branch.municipio
                      ? `${this.state.branch.municipio}, `
                      : ""
                  }${
                    this.state.branch.estado
                      ? `${this.state.branch.estado}, `
                      : ""
                  } ${
                    this.state.branch.country
                      ? `${this.state.branch.country}.`
                      : ""
                  }`}
                  <br />
                  {this.state.branch.col !== "" && (
                    <div>
                      {`Colonia ${this.state.branch.col}, ${this.state.branch.cp} `}
                    </div>
                  )}
                  {`${this.state.branch.calle} ${this.state.branch.noExt}, ${this.state.branch.noInt}`}
                  <br />
                  <b>{this.state.branch.subtitulo}</b> -{" "}
                  {this.state.branch.lugar}
                </div>
                <div className="regular-text section-bottom-25">
                  {existPhone && (
                    <PhoneNumber number={this.state.branch.phone} />
                  )}
                </div>
                <div className="regular-text bold-text section-bottom-25">
                  ¿Qué pasará en tu visita?
                </div>
                <div className="regular-text section-bottom-25">
                  Haremos un diagnóstico y escaneo en 3D. Así, tu ortodoncista
                  moons diseñará tu tratamiento 100% personalizado, sin decenas
                  de citas.
                  <br />
                  <br />
                  1. Tu asesor de sonrisa te hará unas preguntas para conocerte
                  mejor.
                  <br className="in-paragraph-break" />
                  2. Tomaremos fotografías intraorales y extraorales.
                  <br className="in-paragraph-break" />
                  3. Haremos un escaneo en 3D de tu sonrisa. para obtener un
                  modelo digital idéntico a tus dientes.
                  <br className="in-paragraph-break" />
                  4. ¡Listo! Tu ortodoncista moons diseñará tu plan de
                  tratamiento. Enviaremos a tu correo tu diseño de sonrisa y
                  detalles del tratamiento.
                </div>
                <div className="regular-text section-bottom-25">
                  <span className="bold-text">Horarios</span>
                  <br />
                  {this.state.branch.horario &&
                    this.state.branch.horario.split(",").map(item => (
                      <>
                        <span>{item}</span>
                        <br />
                      </>
                    ))}
                  <br />
                </div>
                <div className="office-map section-space-mobile">
                  {this.state.branch.lat > 0 && (
                    <AppointmentMap
                      lat={this.state.branch.lat}
                      lng={this.state.branch.lng}
                      style={{
                        width: "100%",
                        height: "326px"
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="office-column sticky">
                {this.state.loading && (
                  <div className="flex-container">
                    <LoaderSection />
                  </div>
                )}

                <CalendarForm
                  ubicationSpace={false}
                  handleOnChangeText={this.handleOnChangeText}
                  handleSubmit={this.handleSubmit}
                  compact={true}
                  error={this.state.error}
                />

                {this.state.loader && <LoaderPage />}
              </div>
            </div>
          </div>
        </div>
        <div className="section-space">
          <SectionColumns
            imagePosition="right"
            image={[
              dario_alineador_375,
              dario_alineador_960,
              dario_alineador_1500,
              dario_alineador_1800
            ]}
            type="small-image"
          >
            <div>
              <div className="small-title">
                Preguntas frecuentes sobre <br className="break-desktop" /> tu
                cita
              </div>
              <FaqCitas />
            </div>
          </SectionColumns>
        </div>
      </>
    );
  }
}
