import React from 'react';
import styles from "./Pricing.module.css";

export default {
  mx: {
    Header: {
      title: <>
                UNA INVERSIÓN
                <br />
                PARA TODA
                <br />
                LA VIDA
              </>,
      subtitle: null,
      cta: "¿PUEDO USAR MOONS?"
    },
    Prices: {
      title: <>
              Todo lo que necesitas para una
              <br className={styles.breakDesktop} />
              {" "}sonrisa hermosa, por sólo&nbsp;
            </>,
      installments: null,
      appointmentDescription: "Tu escaneo en 3D es gratuito en cualquiera de nuestros centros afiliados del país.",
      diagnosticDescription: "Nuestro equipo de ortodoncistas evalúa tu caso. Aquí te decimos si eres candidato para moons, moons Advanced o no candidato.",
      treatmentDescription: "Tu ortodoncista moons diseña tu nueva sonrisa y crea tu plan de tratamiento personalizado.",
      moonsDescription: "Cuando compras tus moons, empezamos a fabricarlos y los enviamos hasta tu puerta. Sin costos extra ni citas interminables."

    }
  },
  co: {
    Header: {
      title: <>
                UNA INVERSIÓN
                <br />
                PARA TODA
                <br />
                LA VIDA
              </>,
      subtitle: <div className={styles.secondaryText}>
                  Con moons, sigue tu tratamiento prácticamente
                  <br className={styles.breakDesktop} />
                  desde casa. ¡Olvídate del tránsito y las citas 
                  <br className={styles.breakDesktop} />
                  interminables en el dentista!
                </div>,
      cta: "AGENDAR MI CITA"
    },
    Prices: {
      title: <>
              Todo para una sonrisa hermosa
              <br className={styles.breakDesktop} />
              &nbsp;por sólo&nbsp;
            </>,
      installments: <>
                      <br/>
                      <span className={`${styles.extraInfo}`}>
                        hasta en 12 cuotas
                      </span>
                    </>,
      appointmentDescription: "Agenda tu escaneo 3D en uno de nuestros centros afiliados. Si haces el pago antes de asistir a tu cita, ¡pagas sólo $35,000!",
      diagnosticDescription: "Nuestro equipo de ortodoncistas evalúa tu caso. Aquí te decimos si eres candidato para moons, moons Advanced o no candidato.",
      treatmentDescription: "Tu ortodoncista moons diseña tu nueva sonrisa y crea tu plan de tratamiento a medida.",
      moonsDescription: "Una vez que pagas tus moons, empezamos a fabricarlos y los enviamos hasta tu casa. Sin costos extra ni citas interminables."

    }
  }
}