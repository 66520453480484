import React from "react";
import styles from "./SectionColumns.module.css";
const sectionColumns = props => {
  let imageDirection =
    props.imagePosition === "left" ? styles.left : styles.right;
  let textPosition =
    props.imagePosition === "left" ? styles.right : styles.left;
  let image = props.image ? props.image : null;
  let type = props.type ? props.type : null;
  let textStyle = props.textStyle ? styles.smallImage : null;
  let extraIcon = props.extraIcon ? (
    <div className={props.extraIcon}></div>
  ) : null;
  let imageContent = image ? (
    <div
      className={`${styles.sectionBackground} ${styles.sectionSpaceMobile} ${imageDirection} ${type}`}
    >
      {extraIcon}
      <div className={styles.imageContainer}>
        <img
          alt="section-home"
          srcSet={`${image[0]} 375w, ${image[1]} 960w, ${image[2]} 1500w, ${
            image[3]
          } 1800w`}
        />
      </div>
    </div>
  ) : null;

  let heightAuto = image ? null : styles.heightAuto;

  return (
    <div className={styles.sectionContainer}>
      {imageContent}
      <div className={`${styles.sectionFront} ${heightAuto}`}>
        <div
          className={`${styles.sectionColumns} ${textPosition} ${textStyle}`}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default sectionColumns;
