import {
  ACUITY_API_GATEWAY,
  BACKBOX_API,
} from "../../util/appointment/Constants";

const callApi = async (endpoint, options) => {
  const response = await fetch(ACUITY_API_GATEWAY + endpoint, options);
  return await response.json();
};

const callApiBackBox = async (endpoint, options) => {
  const response = await fetch(BACKBOX_API + endpoint, options);
  return await response.json();
};

export const api = {
  acuity: {
    appointmentTypes() {
      return callApi("appointment-types", {
        method: "GET",
      });
    },
    calendars() {
      return callApi("calendars", {
        method: "GET",
      });
    },
    dates(typeId, month, calendarId) {
      return callApi(`dates/${typeId}/${month}/${calendarId}`, {
        method: "GET",
      });
    },
    times(typeId, date, calendarId) {
      return callApi(`times/${typeId}/${date}/${calendarId}`, {
        method: "GET",
      });
    },
    appointments(datetime, appointmentTypeID, name, email, phone) {
      return callApi(`appointments`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          datetime: datetime,
          appointmentTypeID: appointmentTypeID,
          firstName: name,
          lastName: "-",
          email: email,
          phone: phone,
        }),
      });
    },
    datesCalendarTest(month) {
      return callApi(`dates/14065577/${month}/3887965`, {
        method: "GET",
      });
    },
    timesCalendarTest(date) {
      return callApi(`times/14065577/${date}/3887965`, {
        method: "GET",
      });
    },
    appointmentsCalendarTest(datetime, name, email, phone) {
      return callApi(`appointments`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          datetime: datetime,
          appointmentTypeID: 14065577,
          firstName: name,
          lastName: "-",
          email: email,
          phone: phone,
        }),
      });
    },
  },
};

export const blackbox = {
  backbox: {
    blackboxAppoinment(name, phone, email, utcs, origin, country) {
      const request = JSON.stringify({
        email: email,
        phone: phone,
        deal: {
          Patient_Name: name,
          Source: utcs.utm_source,
          Campaign: utcs.utm_campaign,
          Appointment_Source: utcs.utm_appointment_source,
          Ad_Group: utcs.utm_medium,
          Creative_Name: utcs.creative,
          Deal_Origin: origin,
          Country_Ops: country,
        },
        tokenName: "appointments",
      });
      return callApiBackBox(`blackbox`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: request,
      });
    },
    blackboxPaymentSource(name, phone, email, utcs) {
      return callApiBackBox(`blackbox`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          email: email,
          phone: phone,
          deal: {
            Patient_Name: name,
            Source: utcs.utm_source,
            Campaign: utcs.utm_campaign,
            Appointment_Source: utcs.appointment_source,
            Ad_Group: utcs.utm_medium,
            Creative_Name: utcs.creative,
            Payment_Source: utcs.payment_source,
          },
        }),
      });
    },
    blackboxPatient(phone, email, name, utcs, origin, country) {
      return callApiBackBox(`blackbox`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          email: email,
          phone: phone,
          deal: {
            Patient_Name: name,
            Source: utcs.utm_source,
            Campaign: utcs.utm_campaign,
            Ad_Group: utcs.utm_medium,
            Creative_Name: utcs.creative,
            Deal_Origin: origin,
            Country_Ops: country,
          },
        }),
      });
    },
    blackboxUpdateAppointmentSource(phone, email, appointment) {
      return callApiBackBox(`blackbox`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          email: email,
          phone: phone,
          deal: {
            Appointment_Source: appointment,
          },
        }),
      });
    },
    PatientPrice(phone, email, name, utcs, origin, country, link, price) {
      return callApiBackBox(`blackbox`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          email: email,
          phone: phone,
          deal: {
            Patient_Name: name,
            Source: utcs.utm_source,
            Campaign: utcs.utm_campaign,
            Ad_Group: utcs.utm_medium,
            Creative_Name: utcs.creative,
            Deal_Content: utcs.content,
            Deal_Origin: origin,
            Country_Ops: country,
            Videocall_Link: link,
            Final_Price: price,
          },
        }),
      });
    },
  },
};
