import React, { Component } from "react";
import homeReg375 from "../../assets/images/home-375.jpg";
import homeWebp375 from "../../assets/images/webp/home-375.webp";
import homeReg960 from "../../assets/images/home-960.jpg";

class mainSection extends Component {
  render() {
    let defaultImg = this.props.webp ? homeWebp375 : homeReg375;
    let srcSet = this.props.webp
      ? `${homeReg375} 375w, ${homeReg960} 960w`
      : `${homeReg375} 375w, ${homeReg960} 960w`;

    return (
      <div>
        <img alt="main-moons" src={defaultImg} srcSet={srcSet} />
      </div>
    );
  }
}

export default mainSection;
