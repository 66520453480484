import React, { createContext, useState } from "react";

export const Context = createContext();

const Provider = ({ children }) => {
  const [name, setName] = useState(() => window.sessionStorage.NombreMoons);

  const value = {
    name,
    updateName: (newName) => {
      setName(newName);
      window.sessionStorage.NombreMoons = newName;
    },
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default { Provider, Consumer: Context.Consumer };
