import React from 'react';
import styles from "./HowWorks.module.css";

export default {
  mx: {
    Header: {
      title: <>
                FÁCIL,
                <br />
                CÓMODO
                <br />Y RÁPIDO
              </>,
      subtitle: <>
                  Olvídate de las citas interminables en&nbsp;
                  <br className={styles.breakDesktop} />
                  el dentista. Con moons, haz tu
                  <br className={styles.breakDesktop} />
                  tratamiento prácticamente desde casa.
                </>
    },
    Steps: {
      step1Title: "Agenda tu cita",
      step1Description: "Agenda tu Asesoría de Sonrisa. Haremos tu diagnóstico y escaneo en 3D de tus dientes.",
      step2Title: "Mira tus resultados",
      step2Description: "Tu ortodoncista moons diseña tu tratamiento a la medida. Te enviamos un video con tus resultados y duración de tu plan.",
      step3Title: "Empieza tu tratamiento",
      step3Description: "Recibe tus moons hasta tu puerta. Cambia de paso cada 2 semanas ¡y transforma tu sonrisa!"
    },
    NoCandidate: {
      description: <p>
                    Con tu escaneo 3D, es posible que tu ortodoncista determine que
                    tu caso es muy complejo para moons. En esta situación, te
                    ofrecemos{" "}
                    <span className={styles.emphasize}>moons Advanced</span> que
                    incluye citas en persona y varias técnicas ortodónticas para
                    lograr movimientos más avanzados.
                  </p>
    }
  },
  co: {
    Header: {
      title: <>
                FÁCIL,
                <br />
                CÓMODO
                <br />Y RÁPIDO
              </>,
      subtitle: <>
                  Con moons, sigue tu tratamiento prácticamente
                  <br className={styles.breakDesktop} />
                  desde casa. ¡Olvídate del tránsito y las citas 
                  <br className={styles.breakDesktop} />
                  interminables en el dentista!
                </>
    },
    Steps: {
      step1Title: "Pide tu cita de valoración",
      step1Description: "Agenda tu Asesoría de Sonrisa. Haremos tu diagnóstico y escaneo en 3D de tus dientes.",
      step2Title: "Mira tus resultados",
      step2Description: "Tu ortodoncista moons diseña tu tratamiento a medida y te muestra un video con tus resultados y duración.",
      step3Title: "Empieza tu tratamiento",
      step3Description: "Recibe tus moons hasta tu puerta. Cambia de paso cada 2 semanas ¡y transforma tu sonrisa!"
    },
    NoCandidate: {
      description: <p>
                    Durante tu cita de valoración, es posible que el
                    ortodoncista determine que tu caso es complejo para
                    moons. En esta situación, te ofrecemos{" "}
                    <span className={styles.emphasize}>moons Advanced</span> que
                    incluye citas en persona y varias técnicas ortodónticas para
                    lograr movimientos más avanzados.
                  </p>
    }
  }
}