import React, { Component } from "react";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { GOOGLE_MAPS_API_KEY } from "../../util/appointment/Constants";

class AppointmentMap extends Component {
  render() {
    return (
      <Map
        google={this.props.google}
        zoom={17}
        style={this.props.style}
        initialCenter={{
          lat: this.props.lat,
          lng: this.props.lng
        }}
        center={{
          lat: this.props.lat,
          lng: this.props.lng
        }}
      >
        <Marker position={{ lat: this.props.lat, lng: this.props.lng }} />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAPS_API_KEY
})(AppointmentMap);
