import React from "react";
import styles from "./modal.module.css";

class modal extends React.Component {
  close = () => {
    if (this.props.canBeClosed) {
      this.props.onClose();
    }
  };

  render() {
    return this.props.visible ? (
      <div className={styles.modal} onClick={this.close}>
        {this.props.canBeClosed ? (
          <span className={styles.close}>&times;</span>
        ) : null}
        <div className={styles.modalContent}>{this.props.children}</div>
      </div>
    ) : null;
  }
}

export default modal;
