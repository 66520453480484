const mex = {
  advance: 490, // no agrega coma cuando son solo 4 dígitos
  discount: 19990,
  full: 23990,
}

const col = {
  advance: 60000,
  discount: 2800000,
  full: 3500000
}

export default {
  mx: {
    domain: 'mx',
    locale: 'es-MX',
    currency: 'MXN',
    price: {
      num: mex.full,
      str: new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(mex.full),
    },
    advance: {
      num: mex.advance,
      str: '$490',
    },
    discount: {
      active: true,
      price: {
        num: mex.discount,
        str: new Intl.NumberFormat('es-MX', {
          style: 'currency',
          currency: 'MXN',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }).format(mex.discount),
      },
    },
    monthly: "16,490  MXN",
    code: "52",
    phone: "55 8526 5887",
    whatsapp: "55 7100 0303",
    contactEmail: "ayuda@mymoons.mx",
    linkWhatsapp: 'https://api.whatsapp.com/send?phone=525571000303',
    linkInstagram: 'https://www.instagram.com/welovemoons'
  },
  co: {
    domain: 'co',
    locale: 'es-CO',
    currency: 'COP',
    price: {
      num: col.full,
      str: new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(col.full),
    },
    advance: {
      num: col.advance,
      str: new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(col.advance),
    },
    discount: {
      active: true,
      price: {
        num: col.discount,
        str: new Intl.NumberFormat('es-CO', {
          style: 'currency',
          currency: 'COP',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }).format(col.discount),
      },
    },
    monthly: "180.000",
    code: "57",
    phone: "305 252 1482",
    whatsapp: "1 489 9021",
    contactEmail: "hola@mymoons.co",
    linkWhatsapp: 'https://api.whatsapp.com/send?phone=5714899021',
    linkInstagram: 'https://www.instagram.com/welovemoons.co/'
  }
};
