export const dateAcuity = (nextMonth) => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  let date;

  if ((day === 28 && month === 2) || day >= 30) {
    if (month === 12) {
      date = year + 1 + "-01";
    } else {
      date = year + "-" + (month + 1);
    }
  } else {
    if (nextMonth && month === 12) {
      date = year + 1 + "-01";
    } else {
      date = nextMonth ? year + "-" + (month + 1) : year + "-" + month;
    }
  }

  return date;
};

export const getUtms = () => {
  const content = JSON.parse(window.localStorage.getItem("utm_content"));
  const utms = {
    utm_source: JSON.parse(window.localStorage.getItem("utm_source"))[0],
    utm_campaign: JSON.parse(window.localStorage.getItem("utm_campaign"))[0],
    utm_medium: JSON.parse(window.localStorage.getItem("utm_medium"))[0],
    creative: JSON.parse(window.localStorage.getItem("creative"))[0],
    content: content.pop(),
  };

  if (utms.creative === null) {
    utms.creative = "organico";
  }
  return utms;
};
