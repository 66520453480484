import React, { Component } from "react";
import SocialLinks from "../SocialLinks/SocialLinks";
import { NavLink } from "react-router-dom";
import sideMenuStyles from "./Sidemenu.module.css";
import actionbuttonStyles from "../ActionButton/ActionButton.module.css";

class Sidemenu extends Component {
  countryData = {
    mx: {
      phone: "55 8526 5887",
      code: "+52",
    },
    co: {
      phone: "305 252 1482",
      code: "+57",
    },
  };

  handleEvent = () => {
    window.dataLayer.push({ event: "Quiz-View" });
  };

  render() {
    let menuOpen = this.props.menuOpen
      ? sideMenuStyles.open
      : sideMenuStyles.close;
    let classes = [
      sideMenuStyles.sideMenu,
      menuOpen,
      this.props.bannerSpace ? sideMenuStyles.bannerSpace : null,
    ];

    let phone = this.countryData[this.props.country].phone;
    let code = this.countryData[this.props.country].code;

    // let whitening = (
    //   <nav
    //     className={sideMenuStyles.sideMenuItem}
    //     onClick={this.props.toogleMenu}
    //   >
    //     <a
    //       target="_blank"
    //       rel="noopener noreferrer"
    //       href="https://mymoonlight.mx/"
    //     >
    //       BLANQUEAMIENTO
    //     </a>
    //   </nav>
    // );

    let whitening = null;

    return (
      <div className={classes.join(" ")}>
        <div className={sideMenuStyles.sideMenuList}>
          <nav
            className={sideMenuStyles.sideMenuItem}
            onClick={this.props.toogleMenu}
          >
            <NavLink to="/como-funcionan">CÓMO FUNCIONAN</NavLink>
          </nav>
          <nav
            className={sideMenuStyles.sideMenuItem}
            onClick={this.props.toogleMenu}
          >
            <NavLink to="/precio">PRECIO</NavLink>
          </nav>
          {/* <nav
            className={sideMenuStyles.sideMenuItem}
            onClick={this.props.toogleMenu}
          >
            <NavLink to="/agendar-mi-cita">UBICACIONES</NavLink>
          </nav> */}
          {whitening}
          <nav
            className={sideMenuStyles.sideMenuItem}
            onClick={this.props.toogleMenu}
          >
            <a href="https://mymoons.mx/blog">BLOG</a>
          </nav>
          <nav
            className={sideMenuStyles.sideMenuItem}
            onClick={this.props.toogleMenu}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://moonshelp.zendesk.com"
            >
              ¿DUDAS?
            </a>
          </nav>
          <div
            className={sideMenuStyles.sideActionButton}
            onClick={this.props.toogleMenu}
          >
            <NavLink
              onClick={this.handleEvent}
              to="/quizkit/1"
              className={`${actionbuttonStyles.actionButton} ${actionbuttonStyles.largeActionButton} ${actionbuttonStyles.fontBig}`}
            >
              ¡QUIERO MIS MOONS!
            </NavLink>
          </div>
        </div>

        <div className={sideMenuStyles.sideFooter}>
          <div>
            <div className={sideMenuStyles.helpMessage}>
              ¿Necesitas ayuda personal?
            </div>
            <div className={sideMenuStyles.callUs}>
              Llámanos al{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`tel:${code}${phone}`}
              >
                {phone}
              </a>
            </div>
          </div>
          <div className={sideMenuStyles.sideSocialIcons}>
            <SocialLinks blackIcons="true" />
          </div>
        </div>
      </div>
    );
  }
}

export default Sidemenu;
