import _ from "lodash";
import SprintF from "sprintf-js";
import React, { Component } from "react";
import TitleSection from "../TitleSection/TitleSection";
import Medios from "../Medios/Medios";
import MainSectionMobile from "../MainSection/MainSectionMobile";
import MainSectionDinamic from "../MainSection/MainSectionDinamic";
import Steps from "../Home/Steps/Steps";
import Reviews from "../Reviews/Reviews";
import whiteSpace_375 from "../../assets/images/white-space-375.jpg";
import whiteSpace_960 from "../../assets/images/white-space-960.jpg";
import whiteSpace_1500 from "../../assets/images/white-space-1500.jpg";
import whiteSpace_1800 from "../../assets/images/white-space-1800.jpg";

import PayInfo from "../PayInfo/PayInfo";
import Prices from "./Prices/Prices";

import countryTexts from "./countryTexts";
import styles from "./Home.module.css";
import msDynamicStyles from "../MainSection/MainSection.module.css";
import { NavLink } from "react-router-dom";

class Home extends Component {
  state = {
    innerWidth: window.innerWidth,
    country: this.props.country,
    animated: false,
    webp: window.webpSupported,
  };

  componentDidMount() {
    window.criteo_q = window.criteo_q || [];

    var deviceType = /iPad/.test(navigator.userAgent)
      ? "t"
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
        navigator.userAgent
      )
        ? "m"
        : "d";

    if (this.state.country === "mx") {
      window.criteo_q.push(
        { event: "setAccount", account: 67586 }, // You should never update this line
        { event: "setEmail", email: "" },
        { event: "setSiteType", type: deviceType },
        { event: "viewHome" }
      );
    }

    if (this.state.country === "co") {
      window.criteo_q.push(
        { event: "setAccount", account: 67796 }, // You should never update this line
        { event: "setEmail", email: "" },
        { event: "setSiteType", type: deviceType },
        { event: "viewHome" }
      );
    }

    window.addEventListener("resize", () => {
      let resize = _.throttle(() => {
        this.setState({ innerWidth: window.innerWidth });
      }, 1000);
      resize();
    });

    window.addEventListener("scroll", () => {
      let resize = _.throttle(() => {
        let element = document.getElementById("prices");
        if (element) {
          let offsetTop = element.offsetTop;
          let windowOffset = window.pageYOffset + element.offsetHeight;
          let animated = windowOffset >= offsetTop ? true : false;
          this.setState({ animated: animated });
        }
      }, 1000);
      resize();
    });
  }

  handleEvent() {
    window.dataLayer.push({ event: "Quiz-View" });
  }

  render() {
    let countryData = this.props.countryData;

    let animated = this.state.animated ? "animated" : "";
    let sprite =
      this.state.country === "co"
        ? msDynamicStyles.spriteHome
        : msDynamicStyles.spriteHome;

    return (
      <main onScroll={this.handleScroll}>
        {this.state.innerWidth < 800 ? (
          <div className={styles.sectionSpace}>
            <div className={styles.mainMobile}>
              <MainSectionMobile
                webp={this.state.webp}
                sprite={sprite}
                image={[
                  whiteSpace_375,
                  whiteSpace_960,
                  whiteSpace_1500,
                  whiteSpace_1800,
                ]}
              />
            </div>
            <div className={`${styles.sectionColumns} ${styles.mobileColumns}`}>
              <div className={`${styles.mainTitle} ${styles.marginBottom30}`}>
                {countryTexts[countryData.domain].Header.title}
              </div>
              {countryTexts[countryData.domain].Header.subtitle}
            </div>
          </div>
        ) : (
            <div className={styles.sectionSpaceSmall}>
              <MainSectionDinamic
                imagePosition={msDynamicStyles.right}
                image={[
                  whiteSpace_375,
                  whiteSpace_960,
                  whiteSpace_1500,
                  whiteSpace_1800,
                ]}
                sprite={sprite}
                type={msDynamicStyles.mainBackground}
                textStyle={msDynamicStyles.large}
                webp={this.state.webp}
              >
                <div className={styles.titleSpace}>
                  <div className={styles.mainTitle}>
                    {countryTexts[countryData.domain].Header.title}
                  </div>
                  <h1 className={`${styles.marginTop25} ${styles.mainH1}`}>
                    {countryTexts[countryData.domain].Header.subtitle}
                  </h1>
                  <div
                    className={`${styles.marginTopButton} ${styles.marginTop30}`}
                  >
                    <NavLink
                      onClick={this.handleEvent}
                      to="/quizkit/1"
                      className={styles.buttonOutlined}
                    >
                      ¡QUIERO MIS MOONS!
                  </NavLink>
                  </div>
                </div>
              </MainSectionDinamic>
            </div>
          )}
        <div className={styles.sectionSpaceSmall}>
          <Medios countryData={countryData} />
        </div>
        <div className={styles.sectionSpaceBigSmall}>
          <TitleSection />
        </div>
        <div className={styles.sectionSpaceSmall}>
          <Steps
            countryData={countryData}
            texts={countryTexts[countryData.domain].Steps}
          />
        </div>
        <div className={styles.sectionSpaceSmall}>
          <PayInfo country={this.state.country} webp={this.state.webp} />
        </div>
        <div className={`${styles.sectionSpaceSmall}`}>
          <div
            id="prices"
            className={`${styles.flexBackground}`}
          >
            <Prices
              pricesDesc={countryTexts[countryData.domain].Prices.description}
              animated={animated}
              moonsPrice={SprintF.vsprintf(
                countryTexts[countryData.domain].Prices.moonsPrice,
                [
                  countryData.discount.active
                    ? countryData.discount.price.str
                    : countryData.price.str,
                  countryData.monthly,
                ]
              )}
              bracketsPrice={
                countryTexts[countryData.domain].Prices.bracketsPrice
              }
              invisalignPrice={
                countryTexts[countryData.domain].Prices.invisalignPrice
              }
            />
          </div>
          <div className={styles.TaxesChart}>*Todos nuestros precios incluyen IVA</div>
        </div>
        

        <div className={styles.sectionSpaceSmall}>
          <Reviews
            webp={this.state.webp}
            title={countryTexts[countryData.domain].Reviews.title}
          />
        </div>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          alt="tr"
          src="https://www.facebook.com/tr?id=427318704548057&ev=PageView&noscript=1&page=Home"
        />
      </main>
    );
  }
}

export default Home;
