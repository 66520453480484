import _ from "lodash";
import React, { Component } from "react";
import logo from "../../assets/images/logo.svg";
import WhatsApp from "../Whatsapp/Whatsapp";
import Sidemenu from "../Sidemenu/Sidemenu";
import { NavLink } from "react-router-dom";
import whatsappIcon from "../../assets/images/whatsapp.svg";
import headerStyles from "./Header.module.css";
import actionbuttonStyles from "../ActionButton/ActionButton.module.css";

const delta = 5;
let navbarHeight = 0;

class Header extends Component {
  countryData = this.props.countryData;
  country = this.props.country;

  state = {
    didScroll: true,
    lastScrollTop: 0,
    navClass: headerStyles.navDown,
    menuOpen: false,
    st: 0,
    hidePromoBanner: !!sessionStorage.getItem("hidePromoBanner"),
  };

  componentDidMount() {
    navbarHeight = document.getElementById("header").offsetHeight;
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  hideHeader(st) {
    this.setState({
      navClass: headerStyles.navUp,
      st: st,
    });
  }

  showHeader(st) {
    this.setState({
      navClass: headerStyles.navDown,
      st: st,
    });
  }

  getDocHeight() {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
  }

  hasScrolled = () => {
    const st = window.scrollY;
    if (Math.abs(this.state.lastScrollTop - st) <= delta) return;
    if (st > this.state.lastScrollTop && st > navbarHeight) {
      this.hideHeader(st);
    } else {
      if (st < this.getDocHeight()) {
        this.showHeader(st);
      }
    }

    this.setState({
      lastScrollTop: st,
    });
  };

  handleScroll = (event) => {
    if (!this.state.menuOpen) {
      this.setState({
        didScroll: true,
      });

      let scrolled = _.throttle(() => {
        this.hasScrolled();
      }, 250);
      scrolled();
    } else {
      this.setState({
        didScroll: false,
      });
    }
  };

  toogleMenu = () => {
    let menuOpen = !this.state.menuOpen;
    this.setState({ menuOpen: menuOpen });
  };

  handleEvent = () => {
    window.dataLayer.push({ event: "Quiz-View" });
    window.dataLayer.push({ event: "go_to_landbot" });
  };

  handleClickBanner = (e) => {
    window.dataLayer.push({ event: "Quiz-View" });
    window.dataLayer.push({ event: "banner_checkout" });
  };

  hidePromoBanner = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ hidePromoBanner: true }, () =>
      sessionStorage.setItem("hidePromoBanner", true)
    );
  };

  render() {
    let navClass = [
      headerStyles.header,
      this.state.navClass,
      this.state.menuOpen ? headerStyles.open : "",
    ];
    let buttonActivated = this.state.st < 40 ? "" : headerStyles.activated;

    // let headerMX = (
    //   <Link onClick={(e) => this.handleClickBanner(e)} to={"/quizkit/1"}>
    //     <div className={headerStyles.promoHeader}>
    //       <span className={headerStyles.textBar}>
    //         <span
    //           className={headerStyles.Emoticon}
    //           aria-label="mexico flag"
    //           role="img"
    //         >
    //           🔥
    //         </span>{" "}
    //         Inicia tu tratamiento con $990{" "}
    //         <span
    //           className={headerStyles.Emoticon}
    //           aria-label="mexico flag"
    //           role="img"
    //         >
    //           🔥
    //         </span>
    //       </span>
    //     </div>
    //   </Link>
    // );

    //let promoHeader = this.country === "co" ? null : headerMX;
    let promoHeader = null;

    if (this.country === "co" && !this.state.hidePromoBanner && promoHeader) {
      navClass.push(headerStyles.headerPromoHeader);
    } else if (
      this.country === "mx" &&
      !this.state.hidePromoBanner &&
      promoHeader
    ) {
      navClass.push(headerStyles.headerPromoHeader);
    } else {
      promoHeader = null;
    }

    // let whiteningLink =
    //   this.country === "mx" ? (
    //     <div>
    //       <a
    //         className={headerStyles.navLink}
    //         rel="noopener noreferrer"
    //         target="_blank"
    //         href="https://mymoonlight.mx/"
    //       >
    //         BLANQUEAMIENTO
    //         <div className={headerStyles.navSelector}>
    //           <div className={headerStyles.underLink}></div>
    //         </div>
    //       </a>
    //     </div>
    //   ) : null;

    let whiteningLink = null;

    return (
      <div
        className={
          this.state.hidePromoBanner || !promoHeader
            ? ""
            : `${headerStyles.headerSpace}`
        }
      >
        {this.state.hidePromoBanner ? "" : promoHeader}
        <header id="header" className={navClass.join(" ")}>
          <div className={headerStyles.menuLeft}>
            <nav className={headerStyles.logo}>
              <NavLink to="/">
                <img alt="moons" src={logo} />
              </NavLink>
            </nav>
            <nav className={headerStyles.navs}>
              <div>
                <NavLink
                  exact
                  className={`menuLink ${headerStyles.navLink}`}
                  to="/como-funcionan"
                >
                  CÓMO FUNCIONAN
                  <div className={headerStyles.navSelector}>
                    <div className={headerStyles.underLink}></div>
                  </div>
                </NavLink>
              </div>
              <div>
                <NavLink
                  exact
                  className={`menuLink ${headerStyles.navLink}`}
                  to="/precio"
                >
                  PRECIO
                  <div className={headerStyles.navSelector}>
                    <div className={headerStyles.underLink}></div>
                  </div>
                </NavLink>
              </div>
              {/* <div>
                <NavLink
                  exact
                  className={`menuLink ${headerStyles.navLink}`}
                  to="/agendar-mi-cita"
                >
                  UBICACIONES
                  <div className={headerStyles.navSelector}>
                    <div className={headerStyles.underLink}></div>
                  </div>
                </NavLink>
              </div> */}
              {/* <div>
                <NavLink
                  exact
                  className={`menuLink ${headerStyles.navLink}`}
                  to="/opiniones"
                >
                  RESEÑAS
                  <div className={headerStyles.navSelector}>
                    <div className={headerStyles.underLink}></div>
                  </div>
                </NavLink>
              </div> */}
              <div>
                <a
                  className={`menuLink ${headerStyles.navLink}`}
                  href="https://mymoons.mx/blog"
                >
                  BLOG
                  <div className={headerStyles.navSelector}>
                    <div className={headerStyles.underLink}></div>
                  </div>
                </a>
              </div>
              <div>
                <a
                  className={`menuLink ${headerStyles.navLink}`}
                  rel="noopener noreferrer"
                  target="_blank"
                  href="http://moonshelp.zendesk.com"
                >
                  ¿DUDAS?
                  <div className={headerStyles.navSelector}>
                    <div className={headerStyles.underLink}></div>
                  </div>
                </a>
              </div>
              {whiteningLink}
            </nav>
          </div>
          <div>
            <nav className={headerStyles.buttonContainer}>
              <span className={headerStyles.contactLink}>
                <img
                  style={{ width: "20px" }}
                  alt="country-flag"
                  className={headerStyles.countryFlag}
                  src={whatsappIcon}
                />
                <WhatsApp countryData={this.countryData}>
                  {this.countryData.whatsapp}
                </WhatsApp>
              </span>
              <NavLink
                onClick={this.handleEvent}
                to="/quizkit/1"
                className={`${actionbuttonStyles.actionButton} ${actionbuttonStyles.headerButton} ${buttonActivated}`}
              >
                ¡QUIERO MIS MOONS!
              </NavLink>
              <div
                id="menu-button"
                className={`${headerStyles.menuButton} ${
                  this.state.menuOpen ? headerStyles.open : ""
                  }`}
                onClick={this.toogleMenu}
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </nav>
          </div>
        </header>
        <Sidemenu
          country={this.country}
          toogleMenu={this.toogleMenu}
          menuOpen={this.state.menuOpen ? "open" : ""}
          bannerSpace={!this.state.hidePromoBanner || promoHeader}
        />
      </div>
    );
  }
}

export default Header;
