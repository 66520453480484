import React from "react";
import styles from "./Steps.module.css";
import imgs from "../../../siteImages";
import NextButton from "../../NextButton/NextButton";

const steps = ({ texts, countryData }) => {
  return (
    <div className={styles.centerContainer}>
      <div className={styles.stepsContainer}>
        <div className={styles.stepEnclose}>
          <div className={styles.step}>
            <img
              className={styles.stepImage}
              alt="moons escaneo 3D"
              src={imgs.universal.Home.steps.step1()}
            />
          </div>
          <div className={styles.stepTitle}>{texts.step1Title}</div>
          <p className={styles.stepDescription}>{texts.step1Description}</p>
        </div>
        <div className={styles.stepEnclose}>
          <div className={styles.step}>
            <img
              className={styles.stepImage}
              alt="moons plan de tratamiento"
              src={imgs.universal.Home.steps.step2()}
            />
          </div>
          <div className={styles.stepTitle}>{texts.step2Title}</div>
          <p className={styles.stepDescription}>{texts.step2Description}</p>
        </div>
        <div className={styles.stepEnclose}>
          <div className={styles.step}>
            <img
              className={styles.stepImage}
              alt="moons dario yazbek"
              src={imgs[countryData.domain].Home.steps.step3()}
            />
          </div>
          <div className={styles.stepTitle}>{texts.step3Title}</div>
          <p className={styles.stepDescription}>{texts.step3Description}</p>
        </div>
      </div>
      <NextButton
        linkTo="/como-funcionan"
        label="Mira más de nuestro proceso"
        classButton={styles.redEvent}
      />
    </div>
  );
};

export default steps;
