import React from 'react';
import './PhoneNumber.css';

let phoneNumber = (props)=>{
    return(
            <div className="phone-number">
                <div className="phone-number-icon"></div>
                <div className="phone-number-text">
                    <a href={`tel:${props.number}`}>{props.number}</a>
                </div>
            </div>
    );
}


export default phoneNumber;