import React, { Component } from "react";
import styles from "./Calendar.module.css";
import moment from "moment";
import "moment/locale/es";
import { Fecha } from "./Dates";
import { LoaderSection } from "../LoaderSection/LoaderSection";
import next from "../../../assets/images/next-calendar.svg";
import prev from "../../../assets/images/previous.svg";
import { api } from "../../../services/appointment/api";

export class Calendar extends Component {
  constructor() {
    super();
    const today = moment(new Date()).format("YYYY-MM-DD");

    this.state = {
      date: today,
      avalibleDays: [],
      avalibleTimes: [],
      dateSelected: 0,
      loader: true,
      loaderPage: false,
      showButton: false,
    };
  }

  componentDidMount() {
    this.getAvalibleDates(this.state.date);
  }

  getAvalibleDates = async (date) => {
    let searchMonth = moment(date).format("YYYY-MM");
    let avaliblesD = await api.acuity.datesCalendarTest(searchMonth);
    if (avaliblesD.length === 0) {
      searchMonth = moment(searchMonth).add(1, "month");
      avaliblesD = await api.acuity.datesCalendarTest(searchMonth);
    }
    const avaliblesT = await this.getAvalibleTimes(avaliblesD[0].date);
    this.setState({
      avalibleDays: avaliblesD,
      avalibleTimes: avaliblesT,
      loader: false,
    });
  };

  getAvalibleTimes = async (date) => await api.acuity.timesCalendarTest(date);

  updateAcuityData = async (newSelected) => {
    this.setState({
      dateSelected: newSelected,
      loader: true,
      showButton: false,
    });
    const avaliblesT = await this.getAvalibleTimes(
      this.state.avalibleDays[newSelected].date
    );
    this.setState({ avalibleTimes: avaliblesT, loader: false });
  };

  nextDay = async () => {
    const { dateSelected, avalibleDays } = this.state;
    if (dateSelected < avalibleDays.length - 1)
      this.updateAcuityData(dateSelected + 1);
  };

  prevDay = async () => {
    const { dateSelected } = this.state;
    if (dateSelected > 0) this.updateAcuityData(dateSelected - 1);
  };

  showButton = (date) => this.setState({ showButton: true, dateTime: date });

  handleAgendar = async () => {
    const pixel = document.createElement("img");
    pixel.setAttribute(
      "src",
      "https://www.facebook.com/tr?id=427318704548057&ev=Schedule&noscript=1"
    );
    document.getElementById("header").appendChild(pixel);
    const { name, phone, email, link } = this.props;
    this.setState({ loaderPage: true });
    const response = await api.acuity.appointmentsCalendarTest(
      this.state.dateTime,
      name,
      email,
      phone
    );
    this.props.event({ time: response.datetime, link });
  };

  render() {
    moment.locale("es");
    const { avalibleDays, dateSelected, loaderPage } = this.state;

    if (!avalibleDays[dateSelected] || loaderPage) {
      return (
        <div className={styles.LoaderContainer}>
          <LoaderSection />
        </div>
      );
    }

    const title =
      avalibleDays[dateSelected].date === this.state.date ? "Hoy" : "";
    const titleDay = moment(avalibleDays[dateSelected].date).format(
      "DD [de] MMMM [del] YYYY"
    );

    return (
      <div className={styles.Container}>
        <div className={styles.HeaderContent}>
          <div className={styles.HeaderTitle}>{this.props.name}</div>
          <div className={styles.HeaderTitle}>¿Cuándo quieres tu cita?</div>
        </div>
        <div className={styles.Container}>
          <div className={styles.Header}>
            <div className={styles.Arrow} onClick={this.prevDay}>
              <img src={prev} alt="previous day" height="10" />
            </div>
            <div className={styles.TitleContainer}>
              <div className={styles.Title}>{title}</div>
              <div className={styles.Title}>{titleDay}</div>
            </div>
            <div className={styles.Arrow} onClick={this.nextDay}>
              <img src={next} alt="next day" height="10" />
            </div>
          </div>
          {this.state.loader && (
            <div className={styles.LoaderSection}>
              <LoaderSection />
            </div>
          )}
          {!this.state.loader && (
            <div className={styles.DatesContainer}>
              {this.state.avalibleTimes.map((time) => (
                <Fecha
                  date={time.time}
                  key={time.time}
                  event={this.showButton}
                />
              ))}
            </div>
          )}
          {this.state.showButton && (
            <div
              className={styles.ButtonContainer}
              onClick={this.handleAgendar}
            >
              AGENDAR
            </div>
          )}
        </div>
      </div>
    );
  }
}
