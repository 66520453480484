const texts = {
  title: "Esta página web usa cookies",
  body: "\n" +
    "\n" +
    "\n" +
    "Las cookies de este sitio web se usan para personalizar el contenido y los anuncios, ofrecer funciones de redes sociales y analizar el tráfico. Además, compartimos información sobre el uso que haga del sitio web con nuestros partners de redes sociales, publicidad y análisis web, quienes pueden combinarla con otra información que les haya proporcionado o que hayan recopilado a partir del uso que haya hecho de sus servicios.",
  accept: "ACEPTAR"
}

export default texts
